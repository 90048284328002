import React, { Component } from "react";
import State from "../../../utils/State.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Loader from "../../elements/utils/Loader.js";
import Table from "../../elements/data/Table.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";

class RemainingCredits extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "remaining",
        path: "api/finance/credits/remaining"
      })
      .addRessource({
        name: "remainingPercentage",
        path: "api/finance/credits/remaining_percentage"
      })
      .addRessource({
        name: "lastActive",
        path: "api/finance/credits/last_active"
      })
      .addRessource({
        name: "expiringPercentage",
        path: "api/finance/credits/expiring_percentage"
      })
      .addRessource({
        name: "remainingOverview",
        path: "api/finance/credits/remaining_overview"
      })
      .addRessource({
        name: "lastActiveTotal",
        path: "api/finance/credits/last_active_total"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getBaseCreditsTable(
    ressource,
    valueMapper = (x, i) => ValueAdaptor.toAutoUnit(x["values"][i]),
    className = "",
    headingRow = "Date"
  ) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let cols = [
      {
        name: "Purchased At",
        mapper: x => x["purchased_year"],
        isHeader: true
      }
    ];
    for (let i = 0; i < ressource.data["columns"].length; i += 1) {
      cols.push({
        name: `${ressource.data["columns"][i]}`,
        mapper: x => valueMapper(x, i)
      });
    }
    return (
      <Table
        data={ressource.data["rows"]}
        cols={cols}
        className={`base_credits_table ${className}`}
        headingRow={[{ name: headingRow, colSpan: cols.length - 1 }]}
      />
    );
  }

  getLastActivityDate(ressource) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let cols = [
      {
        name: "Purchased At",
        mapper: x => x["purchased_year"],
        isHeader: true
      }
    ];
    cols.push({
      name: `Total`,
      mapper: x =>
        ValueAdaptor.toAutoUnit(x["values"].reduce((a, b) => a + b, 0.0)),
      isHeader: true
    });
    for (let i = 0; i < ressource.data["columns"].length; i += 1) {
      cols.push({
        name: `${ressource.data["columns"][i]}`,
        mapper: x => ValueAdaptor.toAutoUnit(x["values"][i])
      });
    }
    return (
      <Table
        data={ressource.data["rows"]}
        cols={cols}
        className="last_activity_date_table"
        headingRow={[{ name: "Last Activity Date", colSpan: cols.length - 2 }]}
      />
    );
  }

  getExpiredCreditsTable(ressource) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let cols = [
      {
        name: "Purchase Date",
        mapper: x => x["purchased_year"],
        isHeader: true
      }
    ];
    cols.push({
      name: `Total`,
      mapper: x =>
        ValueAdaptor.toPercent(x["values"].reduce((a, b) => a + b, 0.0)),
      isHeader: true
    });
    for (let i = 1; i < ressource.data["columns"].length - 1; i += 1) {
      cols.push({
        name: `N+${i}`,
        mapper: (x, data, rowId) =>
          x["purchased_year"] - 2014 + i + 1 < ressource.data["columns"].length
            ? ValueAdaptor.toPercent(
                x["values"][x["purchased_year"] - 2014 + i]
              )
            : ""
      });
    }
    return (
      <Table
        data={ressource.data["rows"].slice(1)}
        cols={cols}
        className="expired_credits"
        headingRow={[{ name: "At Year", colSpan: cols.length - 2 }]}
      />
    );
  }

  getLastActiveTotal() {
    let ressource = this.state.get().ressources.lastActiveTotal;
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let cols = [
      {
        name: "Last Activity Date",
        mapper: x => ValueAdaptor.toMMYYYY(x["date"]),
        isHeader: true
      },
      {
        name: "Total",
        mapper: x => ValueAdaptor.toAutoUnit(x["credits"], "", 2)
      },
      {
        name: "Cumulated",
        mapper: x => ValueAdaptor.toAutoUnit(x["cumulative_credits"], "", 2)
      }
    ];
    return (
      <Table
        data={ressource.data}
        cols={cols}
        className="expiring_total"
        headingRow={[{ name: "Last Activity Date", colSpan: cols.length - 1 }]}
      />
    );
  }

  getOverview() {
    let ressource = this.state.get().ressources.remainingOverview;
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data["trend"].map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data["trend"].map(e => e.credits);
    return (
      <div className="row">
        <div className="row_item">
          <div className="row_item">
            <SimpleLineChart
              hasAxis={true}
              xName="Date"
              x={x}
              yName="Total"
              y={y}
              is_log_scale={false}
              yToStr={x => ValueAdaptor.toAutoUnit(x)}
            />
          </div>
        </div>
        <div className="row_item">
          <div className="overview_description">
            <h2>Remaining Credits Over Time</h2>
            <p>
              <strong className="unfiltered_remaining_value">
                {ValueAdaptor.toAutoUnit(ressource.data["remaining"], "", 2)}
              </strong>{" "}
              credits are currently remaining on our customers wallets. <br />
              <br />
              <strong>
                {ValueAdaptor.toAutoUnit(ressource.data["filtered"], "", 2)}{" "}
                credits
              </strong>{" "}
              was filtered to process the tables exposed below. <br />
              <br />
              Therefore, we kept{" "}
              <strong className="all_remaining_value">
                {ValueAdaptor.toAutoUnit(
                  ressource.data["remaining"] - ressource.data["filtered"],
                  "",
                  2
                )}
              </strong>{" "}
              remaining credits for the following analysis.
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="page finance remaining_credits">
        <div className="row">
          <div className="row_item">
            <table className="expiring_definition">
              <tbody>
                <tr>
                  <td>Remaining Credits</td>
                  <td>
                    The total number of credits available on our customer
                    accounts.
                  </td>
                </tr>
                <tr>
                  <td>Inactive Accounts</td>
                  <td>
                    Any accounts with no credits spent, purchased, no referrals
                    or gifts during 12 months or more. Admin adjustments and
                    coupon discoveries are not considered as an activity.
                  </td>
                </tr>
                <tr>
                  <td>Expired Credits</td>
                  <td>
                    The total number of remaining credits that have been held by
                    inactive accounts. Because we do not delete expired credits,
                    those credits could have been spent afterward.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row_item">
            <h1>Remaining Credits</h1>
          </div>
        </div>

        {this.getOverview()}
        <div className="row">
          <div className="row_item">
            <h2>Remaining Credits</h2>
            <p>
              Remaining credits year after year : Breakthrough by purchase date.
            </p>
            {this.getBaseCreditsTable(
              this.state.get().ressources.remaining,
              (x, i) => ValueAdaptor.toAutoUnit(x["values"][i]),
              "remaining_credits",
              "At Date"
            )}
          </div>
          <div className="row_item">
            <h2>Remaining / Purchased</h2>
            <p>
              Remaining credits divided by the total number of credits
              purchased.
            </p>
            {this.getBaseCreditsTable(
              this.state.get().ressources.remainingPercentage,
              (x, i) => ValueAdaptor.toPercent(x["values"][i]),
              "",
              "At Year"
            )}
          </div>
        </div>
        <div className="row last_activity">
          <div className="row_item">
            <h2>Credits By Last Activity Date</h2>
            <p>Remaining credits indexed at the last activity date.</p>
            {this.getLastActiveTotal()}
          </div>
          <div className="row_item">
            <h2>Breakthrough By Purchase Date</h2>
            <p>
              Remaining credits indexed at the last activity date : Breakthrough
              by purchase date
              <br />
            </p>
            {this.getLastActivityDate(this.state.get().ressources.lastActive)}
          </div>
        </div>
        <div className="row">
          <h2>Expiry of remaining credits</h2>
          <p>
            <br />
            Using the total number of remaining credits purchased and unspent at
            the end of each year. <br />
            We can estimate the ratio of these credits that would have expired.
            <br />
            <br />
            <br />
          </p>
          {this.getExpiredCreditsTable(
            this.state.get().ressources.expiringPercentage
          )}
        </div>
      </div>
    );
  }
}

export default RemainingCredits;
