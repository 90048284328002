import React, { Component } from "react";
import PropTypes from "prop-types";
import State from "../../../utils/State.js";

class ItemSelector extends Component {
  constructor(props) {
    super(props);
    this.labels = Object.keys(props.items).map(x => `${x}`);
    if (this.labels.length === 0) {
      throw new Error("Selector has no items");
    }
    this.state = new State(this)
      .add("label", props.defaultLabel || this.labels[0])
      .add("value", props.items[props.defaultLabel || this.labels[0]]);
    this.callback = props.callback || (() => {});
  }

  onChange = e => {
    const label = e.target.value;
    this.state.set(s => {
      s.value = this.props.items[label];
      this.callback(this.state.get().value);
    });
  };

  render() {
    return (
      <span className={`selector item_selector ${this.props.className || ""}`}>
        <select defaultValue={this.state.get().label} onChange={this.onChange}>
          {this.labels.map((l, i) => (
            <option key={i} value={`${l}`}>
              {`${l}`}
            </option>
          ))}
        </select>
      </span>
    );
  }
}

ItemSelector.propTypes = {
  defaultLabel: PropTypes.string,
  items: PropTypes.object.isRequired,
  callback: PropTypes.func,
  className: PropTypes.string
};

export default ItemSelector;
