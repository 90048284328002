import React, { Component } from "react";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import ApiAccess from "../../../utils/ApiAccess.js";
import DateSelector from "../../elements/selectors/DateSelector.js";
import InputText from "../../elements/selectors/InputText.js";
import Design from "../../../utils/design.js";
import GrowthLineChart from "../../elements/data/GrowthLineChart.js";

class B2B extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "b2bRevenue",
        path: "api/business/b2b_revenue"
      })
      .addRessource({
        name: "revenueGrowth",
        path: "api/business/b2b_growth_revenue"
      })
      .add("date", ValueAdaptor.utcToday())
      .add("name", null)
      .add("paidAmountCents", null);
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  delete = id => {
    ApiAccess.fetch(
      "api/business/b2b_revenue",
      {},
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": ApiAccess.getCsrfToken()
        },
        body: JSON.stringify({ id: id })
      }
    )
      .then(() => {
        this.state.setPending(this.state.get().ressources.b2bRevenue);
        this.state.fetchAndSet();
      })
      .catch(console.log);
  };

  add = () => {
    if (
      this.state.get().name == null ||
      this.state.get().paidAmountCents == null
    ) {
      return;
    }

    ApiAccess.fetch(
      "api/business/b2b_revenue",
      {},
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": ApiAccess.getCsrfToken()
        },
        body: JSON.stringify({
          date: this.state.get().date,
          name: this.state.get().name,
          paid_amount_cents: this.state.get().paidAmountCents
        })
      }
    )
      .then(() => {
        this.state.setPending(this.state.get().ressources.b2bRevenue);
        this.state.fetchAndSet();
      })
      .catch(console.log);
  };

  getTable() {
    if (!this.state.get().ressources.b2bRevenue.isLoaded) {
      return <Loader />;
    }
    return (
      <Table
        data={this.state.get().ressources.b2bRevenue.data}
        cols={[
          {
            name: "Year",
            mapper: x => ValueAdaptor.toYYYY(x["date"]),
            isHeader: true
          },
          {
            name: "Month",
            mapper: x => ValueAdaptor.toMonthName(x["date"]),
            isHeader: true
          },
          {
            name: "Day",
            mapper: x => ValueAdaptor.toDay(x["date"]),
            isHeader: true
          },
          { name: "Name", mapper: x => x["name"] },
          {
            name: "Total",
            mapper: x => `${Number.parseFloat(x["revenue"]).toFixed(2)}€`
          },
          {
            name: "",
            mapper: x => <button onClick={() => this.delete(x["id"])}>x</button>
          }
        ]}
      />
    );
  }

  buildLineChart() {
    let ressource = this.state.get().ressources.revenueGrowth;
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let xValues = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let yValues = ressource.data.map(e => new Number(e.value));
    let yBaseline = ressource.data.map(e => new Number(e.baseline));

    for (let i = 1; i < yValues.length; i += 1) {
      yValues[i] = yValues[i] + yValues[i - 1];
    }

    for (let i = 1; i < yBaseline.length; i += 1) {
      yBaseline[i] = yBaseline[i] + yBaseline[i - 1];
    }

    let yToStr = y =>
      ValueAdaptor.toAutoUnit(y, `${ressource.meta.unit || ""}`);

    let xToStr = x => `${ValueAdaptor.toDDMMYY(x)}`;

    const lines = [
      {
        color: Design.getColor(0, 2),
        y: yValues,
        yTotal: yValues[yValues.length - 1]
      },
      {
        color: Design.getBaselineColor(),
        y: yBaseline,
        yTotal: yBaseline[yBaseline.length - 1]
      }
    ];

    return (
      <GrowthLineChart
        name="Revenue"
        x={xValues}
        yToStr={yToStr}
        xToStr={xToStr}
        lines={lines}
      />
    );
  }

  render() {
    return (
      <div className="page business b2b">
        <div className="row">
          <div className="row_item">
            {this.buildLineChart()}
          </div>
          <div className="row_item">
            <div className="controller">
              <DateSelector
                defaultDate={this.state.get().date}
                callback={x => this.state.set(s => (s.date = x))}
              />
              <InputText
                defaultText={"name"}
                callback={x => this.state.set(s => (s.name = x))}
              />
              <InputText
                defaultText={"no VAT revenue (€)"}
                callback={x =>
                  this.state.set(
                    s => (s.paidAmountCents = x.replace(",", ".") * 100)
                  )
                }
              />
              <button onClick={() => this.add()}>Add Entry</button>
            </div>
          </div>
          <div className="row_item">
            <h1>B2B</h1>
            <p>All revenues without VAT.</p>
          </div>
        </div>

        {this.getTable()}
      </div>
    );
  }
}
export default B2B;
