import React, { Component } from "react";
import PropTypes from "prop-types";

class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    if (this.props.labels.length < 1 || this.props.values.length < 1 || this.props.values.length != this.props.labels.length) {
      throw new Error("Invalid Argument : labels/values size incorrect");
    }
    this.xToStr = this.props.xToStr || ((x)=>x)
  }
  getContent(){
    let maxValue = Math.max(this.props.values.reduce((acc,v) => Math.max(acc,v)),0.000000000001)
    let entries = [] 
    for(let i =0; i<this.props.labels.length;i++){
        let barWidth=Math.max(this.props.values[i]/maxValue,0)*100;
        if(this.props.isLogScale){
            barWidth=Math.max(Math.log(1+this.props.values[i])/Math.log(1+maxValue),0)*100;
        }
        entries.push(
            <tr key={this.props.labels[i]}>
                <td><span className="label">{this.props.labels[i]}</span></td>
                <td>
                    <span className="bar" style={{width:`calc(${barWidth}% - 60px)`}}></span>
                    <span className="value">{this.xToStr(this.props.values[i])}</span>
                </td>
            </tr>
        )
    }
    return <table><tbody>{entries}</tbody></table>
  }
  render() {
    return (
    <div className={`horizontal_bar_chart ${this.props.className||''}`}>
        {this.getContent()}
    </div>
    );
  }
}

HorizontalBarChart.propTypes = {
  className: PropTypes.string,
  isLogScale: PropTypes.bool,
  labels: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  xToStr:PropTypes.func
};

export default HorizontalBarChart;
