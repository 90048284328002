import React, { Component } from "react";
import Loader from "../../elements/utils/Loader.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Table from "../../elements/data/Table.js";
import State from "../../../utils/State.js";
import Design from "../../../utils/design.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";
import ItemSelector from "../../elements/selectors/ItemSelector.js";

class PostcardLayouts extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "postcardPicturePopularity",
        path: "api/brandcontent/printouts_per_pics",
        params: {
          format: "postcard"
        }
      })
      .addRessource({
        name: "greetingPicturePopularity",
        path: "api/brandcontent/printouts_per_pics",
        params: {
          format: "greeting_card"
        }
      })
      .addRessource({
        name: "layoutPopularity",
        path: "api/brandcontent/printouts_per_layout_code",
        params: {
          format: "postcard"
        }
      })
      .addRessource({
        name: "picturePerCategoryPopularity",
        path: "api/brandcontent/printouts_per_pics_per_category",
        params: {
          format: "postcard"
        }
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getLayoutPopularityTable(ressource) {
    let columns = [
      { name: "layout", mapper: x => x["theme_layout_code"], isHeader: true },
      {
        name: "Preview",
        mapper: x => (
          <img
            src={x["file_path"]}
            className={`layout_thumbnail ${ressource.params.format}`}
          />
        ),
        isHeader: true
      },
      {
        name: "Total",
        mapper: x =>
          ValueAdaptor.toAutoUnit(x.values.reduce((acc, v) => acc + v)),
        isHeader: true
      }
    ];

    for (let i = 0; i < ressource.data.columns.length; i++) {
      columns.push({
        name: ValueAdaptor.toMMYYYY(ressource.data.columns[i]),
        mapper: x => ValueAdaptor.toAutoUnit(x.values[i])
      });
    }
    return <Table data={ressource.data.rows} cols={columns} />;
  }

  getPicturePerCategoryTable(ressource) {
    let columns = [
      { name: "category", mapper: x => x["category_name"], isHeader: true },
      {
        name: "Tot,Pics>>",
        mapper: x =>
          ValueAdaptor.toPercent(x.values.reduce((acc, v) => acc + v))
      }
    ];

    for (let i = 0; i < ressource.data.columns.length; i++) {
      columns.push({
        name: "" + ressource.data.columns[i],
        mapper: x => ValueAdaptor.toPercent(x.values[i])
      });
    }

    return <Table data={ressource.data.rows} cols={columns} />;
  }

  getTotalPicsMultiLinesChart(ressource) {
    let lines = [];

    for (let i = 0; i < ressource.data.columns.length; i++) {
      lines.push({
        name: `${ressource.data.columns[i]}pics`,
        color: Design.getColor(i, ressource.data.columns.length),
        y: ressource.data.rows.map(x => x.values[i])
      });
    }
    let x = ressource.data.rows.map(x => ValueAdaptor.parseUTCDate(x["date"]));
    return (
      <MultiLinesChart
        x={x}
        xToStr={ValueAdaptor.toDDMMYYYY}
        yToStr={ValueAdaptor.toPercent}
        lines={lines}
        hasAxis={true}
        is_log_scale={false}
      />
    );
  }

  getCardControler() {
    return (
      <div className="controler">
        <ItemSelector
          items={{
            Postcards: "postcard",
            "Postcards Magnet": "postcard_magnet",
            "Square Cards Magnet": "square_card_magnet",
            Greetings: "greeting_card",
            "Postcard Video": "postcard_video",
            "Postcards [SET]": "set_of_postcards",
            "Greetings [SET]": "set_of_greeting_cards"
          }}
          defaultLabel="Postcards"
          callback={x => {
            this.state.set(s => {
              s.ressources["picturePerCategoryPopularity"].params.format = x;
              s.ressources["layoutPopularity"].params.format = x;
            });
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="page brandcontent postcard_layouts">
        <h1>Postcard Layouts</h1>
        <div className="row">
          <div className="row_item">
            <h3>Per Picture Printouts [Postcards]</h3>
            {this.state.get().ressources.postcardPicturePopularity.isLoaded ? (
              this.getTotalPicsMultiLinesChart(
                this.state.get().ressources.postcardPicturePopularity
              )
            ) : (
              <Loader />
            )}
          </div>
          <div className="row_item">
            <h3>Per Picture Printouts [Greetings]</h3>
            {this.state.get().ressources.greetingPicturePopularity.isLoaded ? (
              this.getTotalPicsMultiLinesChart(
                this.state.get().ressources.greetingPicturePopularity
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>

        {this.getCardControler()}
        <div className="row">
          <div className="row_item">
            <h3>Printouts Per Picture & Per Category [FR Last 365 Days]</h3>
            {this.state.get().ressources.picturePerCategoryPopularity
              .isLoaded ? (
              this.getPicturePerCategoryTable(
                this.state.get().ressources.picturePerCategoryPopularity
              )
            ) : (
              <Loader />
            )}
          </div>
          <div className="row_item">
            <h3>Printouts Per Layout</h3>
            {this.state.get().ressources.layoutPopularity.isLoaded ? (
              this.getLayoutPopularityTable(
                this.state.get().ressources.layoutPopularity
              )
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PostcardLayouts;
