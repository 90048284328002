import React, { Component } from "react";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";

class Contacts extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "allContacts",
        path: "api/product/contact_counter"
      })
      .addRessource({
        name: "birthdateContacts",
        path: "api/product/contact_counter",
        params: { has_birthdate: true }
      })
      .addRessource({
        name: "groupedContacts",
        path: "api/product/contact_counter",
        params: { has_group: true }
      })
      .addRessource({
        name: "bulkImportContacts",
        path: "api/product/contact_counter",
        params: { is_bulk_import: true }
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getContacts(ressource) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(e => e.total);
    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Total"
        y={y}
        is_log_scale={false}
        yToStr={x => ValueAdaptor.toAutoUnit(x)}
      />
    );
  }

  render() {
    return (
      <div className="page product contacts">
        <h1>Contacts</h1>
        <div className="row">
          <div className="rowItem">
            <h2>All</h2>
            <p>
              The total number of contacts (cumulative) indexed at the contact
              creation date.
            </p>
            {this.getContacts(this.state.get().ressources.allContacts)}
          </div>
          <div className="rowItem">
            <h2>With Birthdates</h2>
            <p>
              The total number of contacts with a birthdate (cumulative) indexed
              at the contact creation date.
            </p>
            {this.getContacts(this.state.get().ressources.birthdateContacts)}
          </div>
        </div>
        <div className="row">
          <div className="rowItem">
            <h2>Group Contacts</h2>
            <p>
              The total number of contacts (cumulative) assigned to a group
              indexed at the contact creation date.
            </p>
            {this.getContacts(this.state.get().ressources.groupedContacts)}
          </div>
          <div className="rowItem">
            <h2>Phone Sync and Csv Imports</h2>
            <p>
              The total number of contacts added using the bulk import feature
              (cumulative) indexed at the contact creation date.
            </p>
            {this.getContacts(this.state.get().ressources.bulkImportContacts)}
          </div>
        </div>
      </div>
    );
  }
}

export default Contacts;
