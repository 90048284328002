import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import DateSelector from "../../elements/selectors/DateSelector.js";
import ApiAccess from "../../../utils/ApiAccess.js";

class Costs extends Component {
  constructor(params) {
    super(params);

    this.fromDate = ValueAdaptor.addDays(ValueAdaptor.utcToday(), -365);
    this.untilDate = ValueAdaptor.utcToday();

    this.state = new State(this)
      .addRessource({
        name: "costDetails",
        path: "api/finance/cost_details",
        params: {
          from_date: this.fromDate,
          until_date: this.untilDate
        },
        callback: ressource => {
          this.state.get()["costsResume"] = this.getCostsResumeData(
            ressource.data
          );
        }
      })
      .add("costsResume", undefined)
      .add("hasPendingSave", false);
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getInputTextCallback = (
    productName,
    serviceLabel,
    row,
    value_id,
    variables,
    unit = ""
  ) => {
    let callback = inputTextValue => {
      if (inputTextValue) {
        row[value_id] = inputTextValue;
        this.state.set(x => {
          x["costsResume"] = this.getCostsResumeData(
            this.state.get().ressources["costDetails"].data
          );
        });
      } else {
        row[value_id] = "0";
      }
    };

    return (
      <div
        className="input_text_wrapper"
        key={`parameters_${productName}_${serviceLabel}`}
      >
        <input
          className="input_text"
          type="text"
          onKeyUp={e => callback(e.target.value || "0")}
          onFocus={e => (e.target.value = row[value_id])}
          onBlur={e =>
            (e.target.value = this.resolveFormula(row[value_id], variables))
          }
          defaultValue={this.resolveFormula(row[value_id], variables)}
          placeholder={this.resolveFormula(row[value_id], variables)}
        ></input>
        {unit}
      </div>
    );
  };

  getCostsResume() {
    let costsResume = this.state.get()["costsResume"];

    if (!costsResume) {
      return (
        <div className="data_table costs">
          <Loader />
        </div>
      );
    }

    let cols = [
      {
        name: "Product",
        mapper: x => x["product_name"].replace(/_/g, " "),
        isHeader: true
      }
    ];

    for (let i = 0; i < costsResume["columns"].length; i += 1) {
      cols.push({
        name: `${costsResume["columns"][i].replace(/_/g, " ")}`,
        mapper: x => ValueAdaptor.toAutoUnit(x["values"][i], "€", 4)
      });
    }
    cols = cols.concat([
      { name: "Usage %", mapper: x => ValueAdaptor.toPercent(x["usage_rate"]) },
      {
        name: "Costs",
        mapper: x => ValueAdaptor.toAutoUnit(x["costs_per_product"], "€", 2)
      },
      {
        name: "Credits",
        mapper: x => ValueAdaptor.toAutoUnit(x["credits_per_product"], "", 1)
      },
      {
        name: "Cost/Credits",
        mapper: x => ValueAdaptor.toAutoUnit(x["costs_per_credit"], "€", 4)
      }
    ]);
    return <Table className="costs" data={costsResume["rows"]} cols={cols} />;
  }

  getVariables() {
    let costDetails = this.state.get().ressources["costDetails"];
    if (!costDetails.isLoaded) {
      return (
        <div className="data_table table_variables">
          <Loader />
        </div>
      );
    }
    let cols = [
      {
        name: "Name",
        mapper: x => x["name"],
        isHeader: true
      },
      {
        name: "Value",
        mapper: x => x["value"]
      }
    ];
    return (
      <Table
        className="variables"
        data={costDetails.data["variables"]}
        cols={cols}
      />
    );
  }

  resolveFormula(x, variables) {
    let value = 1.0;
    for (let v of (x.toString() || "0").split("*")) {
      if (
        v.trim() in variables &&
        variables[v.trim()] != undefined &&
        !isNaN(variables[v.trim()]) &&
        variables[v.trim()] != ""
      ) {
        value *= parseFloat(variables[v.trim()]);
      } else if (!isNaN(v) && v !== undefined && v != "") {
        value *= parseFloat(v);
      } else {
        value = 0;
      }
    }
    return value;
  }

  getCostsResumeData = data => {
    let variables = data["variables"].reduce((acc, v) => {
      acc[v["name"]] = v["value"];
      return acc;
    }, {});

    let results = {
      columns: [...new Set(data["costs"]["rows"].map(x => x["type"]))]
    };

    let typeToId = results["columns"].reduce((acc, v, id) => {
      acc[v] = id;
      return acc;
    }, {});

    let rows = data["costs"]["rows"].reduce(
      function(acc, row) {
        for (let i = 0; i < data["costs"]["columns"].length; i += 1) {
          let product = data["costs"]["columns"][i];
          acc[product] = acc[product] || results["columns"].map(x => 0.0);
          let value = this.resolveFormula(row["values"][i], variables);
          let units_per_service = Math.max(
            this.resolveFormula(row["units_per_service"], variables),
            0.001
          );
          let service_cost = this.resolveFormula(row["cost"], variables);
          if (row["units_per_service"] == 0 && value > 0) {
            acc[product][typeToId[row["type"]]] = undefined;
          } else if (acc[product][typeToId[row["type"]]] !== undefined) {
            acc[product][typeToId[row["type"]]] +=
              (value / units_per_service) * service_cost;
          }
        }
        return acc;
      }.bind(this),
      {}
    );

    rows = data["costs"]["columns"].map(product => {
      return {
        product_name: product,
        values: rows[product]
      };
    });

    let productToCreditsUsage = data["credits_usage"].reduce((acc, v) => {
      acc[v["product_name"]] = v;
      return acc;
    }, {});

    rows.forEach(row => {
      row["costs_per_product"] = row["values"].reduce((a, b) => a + b, 0);
      let product_cost_per_product =
        row["costs_per_product"] - row["values"][typeToId["postage"]];
      row["usage_rate"] =
        productToCreditsUsage[row["product_name"]]["credits_percentage"];
      row["credits_per_product"] =
        productToCreditsUsage[row["product_name"]]["avg_credits"];
      row["costs_per_credit"] =
        row["credits_per_product"] == 0
          ? undefined
          : row["costs_per_product"] / row["credits_per_product"];
      row["postage_costs_per_credit"] =
        row["credits_per_product"] == 0
          ? undefined
          : row["values"][typeToId["postage"]] / row["credits_per_product"];
      row["product_costs_per_credit"] =
        row["credits_per_product"] == 0
          ? undefined
          : product_cost_per_product / row["credits_per_product"];
    });

    let total = {
      product_name: "Total",
      values: results["columns"].map(x => undefined),
      costs_per_product: undefined,
      usage_rate: rows
        .map(r => r["usage_rate"])
        .reduce((acc, v) => acc + v, 0.0),
      credits_per_product: undefined,
      costs_per_credit: rows
        .map(r => (r["costs_per_credit"] || 0.0) * r["usage_rate"])
        .reduce((acc, v) => acc + v, 0.0)
    };
    let postage_total = {
      product_name: "Postage Total",
      values: results["columns"].map(x => undefined),
      costs_per_product: undefined,
      usage_rate: undefined,
      credits_per_product: undefined,
      costs_per_credit: rows
        .map(r => (r["postage_costs_per_credit"] || 0.0) * r["usage_rate"])
        .reduce((acc, v) => acc + v, 0.0)
    };
    let product_total = {
      product_name: "Product Total",
      values: results["columns"].map(x => undefined),
      costs_per_product: undefined,
      usage_rate: undefined,
      credits_per_product: undefined,
      costs_per_credit: rows
        .map(r => (r["product_costs_per_credit"] || 0.0) * r["usage_rate"])
        .reduce((acc, v) => acc + v, 0.0)
    };

    rows.push(postage_total);
    rows.push(product_total);
    rows.push(total);
    results["rows"] = rows;
    return results;
  };

  saveParameters = () => {
    if (
      !this.state.get().ressources.costDetails.isLoaded &&
      !this.state.get()["hasPendingSave"]
    ) {
      return;
    }
    this.state.set(s => {
      s["hasPendingSave"] = true;
    });

    ApiAccess.fetch(
      "api/finance/save_cost_parameters",
      {},
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": ApiAccess.getCsrfToken()
        },
        body: JSON.stringify(
          this.state.get().ressources.costDetails.data["costs"]
        )
      }
    )
      .then(() => {
        this.state.set(s => {
          s["hasPendingSave"] = false;
        });
        this.state.setPending(this.state.get().ressources.costDetails);
        this.state.fetchAndSet();
      })
      .catch(console.log);
  };

  getCostParameters() {
    let ressource = this.state.get().ressources["costDetails"];

    if (!ressource.isLoaded) {
      return <Loader />;
    }

    let variables = ressource.data["variables"].reduce((acc, v) => {
      acc[v["name"]] = v["value"];
      return acc;
    }, {});

    let cols = [
      {
        name: "Type",
        mapper: x => x["type"].replace(/_/g, " "),
        isHeader: true
      },
      {
        name: "Label",
        mapper: x => x["label"].replace(/_/g, " "),
        isHeader: true
      }
    ];

    for (let i = 0; i < ressource.data["costs"]["columns"].length; i += 1) {
      cols.push({
        name: `${ressource.data["costs"]["columns"][i].replace(/_/g, " ")}`,
        mapper: row =>
          this.getInputTextCallback(
            ressource.data["costs"]["columns"][i],
            row["label"],
            row["values"],
            i,
            variables
          )
      });
    }

    cols = cols.concat([
      {
        name: "Units Per Purchase",
        mapper: row =>
          this.getInputTextCallback(
            "Units Per Purchase",
            row["label"],
            row,
            "units_per_service",
            variables
          )
      },
      {
        name: "Service Costs",
        mapper: row =>
          this.getInputTextCallback(
            "Service Costs",
            row["label"],
            row,
            "cost",
            variables,
            "€"
          )
      }
    ]);

    return (
      <Table
        className="cost_details"
        data={ressource.data["costs"]["rows"]}
        cols={cols}
      />
    );
  }

  getDateSelector() {
    return (
      <div className="date_selection">
        From
        <DateSelector
          defaultDate={this.fromDate}
          callback={x => {
            this.state.set(s => {
              s.ressources.costDetails.params["from_date"] = x;
            });
          }}
        />
        until
        <DateSelector
          defaultDate={this.untilDate}
          callback={x => {
            this.state.set(s => {
              s.ressources.costDetails.params["until_date"] = x;
            });
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="page finance costs">
        <div className="cost_controler">
          <h1>Production Costs</h1>
          {this.getDateSelector()}
          <div>
            <button
              className="save"
              onClick={() => {
                this.state.setPending(this.state.get().ressources.costDetails);
                this.state.fetchAndSet();
              }}
            >
              Reset
            </button>
            <button
              style={
                this.state.get().hasPendingSave
                  ? { backgroundColor: "#ff2828" }
                  : {}
              }
              onClick={this.saveParameters}
              className="save"
            >
              Save
            </button>
          </div>
        </div>
        <div className="table_wrapper costs">
          <h2>
            <span>Product Unit Cost</span>
          </h2>
          {this.getCostsResume()}
          <div className="table_variables">
            <h2>Variables</h2>
            {this.getVariables()}
          </div>
        </div>
        <div className="table_wrapper cost_parameters">
          <h2>
            <span>Parameters</span>
          </h2>
          {this.getCostParameters()}
        </div>
      </div>
    );
  }
}

export default Costs;
