import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import DehazeIcon from "@material-ui/icons/Dehaze";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import State from "../../../utils/State";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = new State(this).add("isExpanded", true);
  }

  buildItem = item => {
    let dataMethod = item.dataMethod === undefined ? "get" : item.dataMethod;
    if (item.path !== undefined) {
      return (
        <NavLink exact to={item.path} data-method={dataMethod}>
           <span className="label">{item.label}</span>
        </NavLink>
      );
    } else {
      return (
        <span className="menu_category">
          <span className="label">{item.label}</span>
        </span>
      );
    }
  };

  displayItems = (item, key) => {
    return (
      <li key={key}>
        {this.buildItem(item)}
        <ul>{(item.childs || []).map(this.displayItems)}</ul>
      </li>
    );
  };

  setExpanded = value => {
    this.state.set(s => {
      s.isExpanded = value;
    });
  };

  expandMenuButton = () => {
    let reduceButton = (
      <button onClick={() => this.setExpanded(false)}>
        <FirstPageIcon className="menu_icon" />
      </button>
    );
    let expandButton = (
      <button onClick={() => this.setExpanded(true)}>
        <DehazeIcon className="menu_icon" />
      </button>
    );
    let clickable = this.state.get().isExpanded ? reduceButton : expandButton;
    return <div className="expand_menu">{clickable}</div>;
  };

  render() {
    return (
      <div
        className={`sidebar ${
          this.state.get().isExpanded ? "expanded" : "reduced"
        }`}
      >
        <ul className="menu">
          {this.state.get().isExpanded &&
            this.props.items.map((item, index) =>
              this.displayItems(item, index)
            )}
        </ul>
      </div>
    );
  }
}

export default SideBar;
