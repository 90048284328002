import ApiAccess from "./ApiAccess.js";

class State {
  constructor(component, params) {
    this.component = component;
    this.params = params || {};
    if (!("ressources" in this.params)) {
      this.params.ressources = {};
    }
  }

  get = () => this.params;

  add = (key, value) => {
    this.params[key] = value;
    return this;
  };

  addRessource = ressource => {
    this.params.ressources[ressource.name] = {
      path: ressource.path,
      params: ressource.params || {},
      callback:ressource.callback,
      data: [],
      isLoaded: false,
      sortBy: ressource.sortBy,
      meta: ressource.meta || {},
      lastPullHash: ""
    };
    return this;
  };

  setPending = ressource => {
    ressource.isLoaded = false;
    ressource.lastPullHash = "";
  };

  getPullHash = ressource => {
    return ApiAccess.getUrl(ressource.path, ressource.params);
  };

  set = applyFunc => {
    this.component.setState(
      prevState => {
        applyFunc(prevState.params);
        return new State(this.component, this.params);
      },
      () => this.component.state.fetchAndSet()
    );
  };

  fetchAndSet = () => {
    for (let name in this.params.ressources) {
      let ressourceName = name;
      let ressource = this.params.ressources[ressourceName];
      let currPullHash = this.getPullHash(this.params.ressources[name]);

      if (currPullHash !== ressource.lastPullHash) {
        ressource.lastPullHash = currPullHash;
        this.component.setState(prev => {
          this.params.ressources[ressourceName].isLoaded = false;
          return new State(this.component, this.params);
        });

        let fetch = ressource =>
          ApiAccess.fetch(ressource.path, ressource.params).then(data => {
            this.component.setState(prev => {
              if (currPullHash !== ressource.lastPullHash) {
                return prev;
              }
              if (ressource.sortBy) {
                data.sort((a, b) => ressource.sortBy(a) - ressource.sortBy(b));
              }
              prev.params.ressources[ressourceName].data = data;
              prev.params.ressources[ressourceName].isLoaded = true;
              if(prev.params.ressources[ressourceName].callback){
                prev.params.ressources[ressourceName].callback(prev.params.ressources[ressourceName])
              }
              return new State(prev.component, prev.params);
            });
          });
        let wait = delay => new Promise(resolve => setTimeout(resolve, delay));
        fetch(ressource)
          .catch(x => wait(2000).then(x => fetch(ressource)))
          .catch(x => wait(5000).then(x => fetch(ressource)));
      }
    }
  };
}

export default State;
