import React, { Component } from "react";
import PropTypes from "prop-types";
import LineChart from "./LineChart.js";
import State from "../../../utils/State.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";

class MultiLinesChart extends Component {

  constructor(props) {
    super(props);

    if (this.props.lines.length < 2 || this.props.x.length == 0) {
      throw new Error(
        "Invalid Argument : MultiLinesChart requires 2 lines plus data"
      );
    }

    this.xToStr = x => {
      if (x instanceof Date) {
        return ValueAdaptor.toDDMMYYYY(x);
      }
      return x.toFixed(1);
    };

    this.xToStr = props.xToStr ? props.xToStr : this.xToStr;
    this.yToStr = props.yToStr ? props.yToStr : y => y.toFixed(1);

    let displayedY = [];
    for (let line of props.lines) {
      displayedY.push({
        name: line.name,
        color: line.color,
        value: line.y[this.props.selectedId || (line.y.length - 1)]
      });
    }

    this.defaultState = {
      x: this.props.x[this.props.selectedId || (this.props.x.length - 1)],
      lines: displayedY
    };

    this.state = new State(this)
    .add("x", this.defaultState.x)
    .add(
      "lines",
      this.defaultState.lines.map(x => ({ ...x }))
    );

    this.totalLabelsPerColumn = props.totalLabelsPerColumn || 13;
    this.maxLabelStringLength = props.maxLabelStringLength || 14;
  }

  hover = selId => {
    if (selId === undefined) {
      this.state.set(s => {
        s.x = this.defaultState.x;
        s.lines = this.defaultState.lines.map(x => ({ ...x }));
      });
    }
    else {
      this.state.set(s => {
        s.x = this.props.x[selId];
        for (let i = 0; i < this.props.lines.length; i++) {
          s.lines[i].value = this.props.lines[i].y[selId];
        }
      });
    }
    if (this.props.hover) {
      this.props.hover(selId);
    }
  };

  getInfoCells() {

    let cells = [
      <div key="xValue" className="info_cell x_value">
        <span className="label_value">
        {this.props.xToStr(this.state.get().x)}
        </span>
      </div>
    ].concat(this.state.get().lines.map(y => (
      <div
        className="info_cell"
        key={y.name}
        style={{ border: `2px solid ${y.color}` }}
      >
        <div>
          <span
            className="label_color"
            style={{ backgroundColor: y.color }}
          ></span>
          <span className="label_name">
            {ValueAdaptor.shortString(y.name, this.maxLabelStringLength)}
          </span>
        </div>
        <div>
          <span className="label_value">{this.props.yToStr(y.value)}</span>
        </div>
      </div>
    )));
    return (<div className="info_wrapper">{cells}</div>);
  }

  render() {
    return (
      <div className="multi_lines_chart">
        <LineChart
          hasAxis={this.props.hasAxis}
          x={this.props.x}
          is_log_scale={this.props.is_log_scale}
          hover={this.hover}
          lines={this.props.lines}
          selectedId={this.props.selectedId}
        />
        {this.getInfoCells()}
      </div>
    );
  }
}

MultiLinesChart.propTypes = {
  hasAxis: PropTypes.bool.isRequired,
  is_log_scale: PropTypes.bool,
  x: PropTypes.array.isRequired,
  xToStr: PropTypes.func.isRequired,
  yToStr: PropTypes.func.isRequired,
  hover: PropTypes.func,
  maxLabelStringLength: PropTypes.number,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      y: PropTypes.array.isRequired
    })
  ),
  selectedId: PropTypes.number
};

export default MultiLinesChart;