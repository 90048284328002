import React, { Component } from "react";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Table from "../../elements/data/Table.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class BusinessScorecard extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "scorecard",
      path: "api/business/scorecard",
      params: { timescale: "month" }
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getTimescaleButtonSelector(timescale) {
    return (
      <button
        type="button"
        className={
          this.state.get().ressources.scorecard.params.timescale === timescale
            ? "active_button"
            : "inactive_button"
        }
        onClick={() =>
          this.state.set(s => {
            s.ressources.scorecard.params.timescale = timescale;
          })
        }
      >
        {timescale}
      </button>
    );
  }

  getColumns() {
    let columns = [
      {
        name: "Year",
        mapper: x => ValueAdaptor.toYYYY(x.date),
        isHeader: true
      }
    ];

    let timescale = this.state.get().ressources.scorecard.params.timescale;

    if (timescale === "month" || timescale === "day") {
      columns.push({
        name: "month",
        mapper: x => ValueAdaptor.toMonthName(x.date),
        isHeader: true
      });
    }

    if (timescale === "day") {
      columns.push({
        name: "day",
        mapper: x => ValueAdaptor.toDay(x.date),
        isHeader: true
      });
    }
    columns = columns.concat([
      {
        name: "€",
        mapper: x => ValueAdaptor.toAutoUnit(x.revenue_curr, "€", 2)
      },
      {
        name: "€(New)",
        mapper: x => ValueAdaptor.toAutoUnit(x.new_revenue_curr, "€", 2)
      },
      {
        name: "€(Repeat)",
        mapper: x => ValueAdaptor.toAutoUnit(x.repeat_revenue_curr, "€", 2)
      },
      {
        name: "€(B2B)",
        mapper: x => ValueAdaptor.toAutoUnit(x.b2b_revenue, "€", 2)
      },
      {
        name: "€ YtY",
        mapper: x => ValueAdaptor.toGrowth(x.revenue_curr, x.revenue_prev)
      },
      {
        name: "Regs",
        mapper: x => ValueAdaptor.toAutoUnit(x.subscriptions_curr, "", 2)
      },
      {
        name: "Regs YtY",
        mapper: x =>
          ValueAdaptor.toGrowth(x.subscriptions_curr, x.subscriptions_prev)
      },
      {
        name: "Actis",
        mapper: x => ValueAdaptor.toAutoUnit(x.activations_curr, "", 2)
      },
      {
        name: "Actis YtY",
        mapper: x =>
          ValueAdaptor.toGrowth(x.activations_curr, x.activations_prev)
      },
      {
        name: "New € YtY",
        mapper: x =>
          ValueAdaptor.toGrowth(x.new_revenue_curr, x.new_revenue_prev)
      },
      {
        name: "Repeat € YtY",
        mapper: x =>
          ValueAdaptor.toGrowth(x.repeat_revenue_curr, x.repeat_revenue_prev)
      },
      {
        name: "Foreign € YtY",
        mapper: x =>
          ValueAdaptor.toGrowth(x.foreign_revenue_curr, x.foreign_revenue_prev)
      },
      {
        name: "%(New €)",
        mapper: x => ValueAdaptor.toPercent(x.new_revenue_curr / x.revenue_curr)
      },
      {
        name: "%(Foreign €)",
        mapper: x =>
          ValueAdaptor.toPercent(x.foreign_revenue_curr / x.revenue_curr)
      },
      {
        name: "Credits Spent",
        mapper: x => ValueAdaptor.toAutoUnit(x.credits_spent, "", 2)
      },
      {
        name: "€(Postcard)",
        mapper: x => ValueAdaptor.toAutoUnit(x.postcard_revenue, "€", 2)
      },
      {
        name: "€(Greeting)",
        mapper: x => ValueAdaptor.toAutoUnit(x.greeting_revenue, "€", 2)
      },
      {
        name: "€(Card Magnet)",
        mapper: x => ValueAdaptor.toAutoUnit(x.postcard_magnet_revenue, "€", 2)
      },
      {
        name: "€(Square Magnet)",
        mapper: x =>
          ValueAdaptor.toAutoUnit(x.square_card_magnet_revenue, "€", 2)
      },
      {
        name: "€(Card Video)",
        mapper: x => ValueAdaptor.toAutoUnit(x.postcard_video_revenue, "€", 2)
      },
      {
        name: "€(Magnet Video)",
        mapper: x => ValueAdaptor.toAutoUnit(x.magnet_video_revenue, "€", 2)
      },
      {
        name: "€(Set)",
        mapper: x => ValueAdaptor.toAutoUnit(x.set_revenue, "€", 2)
      },
      {
        name: "€(Book)",
        mapper: x => ValueAdaptor.toAutoUnit(x.book_revenue, "€", 2)
      },
      {
        name: "€(Gazette)",
        mapper: x => ValueAdaptor.toAutoUnit(x.gazette_revenue, "€", 2)
      },
      {
        name: "Acti % (30days)",
        mapper: x =>
          x.subscriptions_for_activations
            ? ValueAdaptor.toPercent(
                x.activations_30days / x.subscriptions_for_activations
              )
            : "-"
      },
      {
        name: "Zen Tickets",
        mapper: x => ValueAdaptor.toAutoUnit(x.zen_tickets, "", 2)
      }
    ]);

    return columns;
  }

  render() {
    let table = (
      <Table
        data={this.state.get().ressources.scorecard.data}
        cols={this.getColumns()}
      />
    );
    let loader = <Loader />;

    return (
      <div className="page business scorecard">
        <div className="controler">
          <div className="selector aggregation_by">
            {["day", "month", "year"].map(ts =>
              this.getTimescaleButtonSelector(ts)
            )}
          </div>
        </div>
        {this.state.get().ressources.scorecard.isLoaded ? table : loader}
      </div>
    );
  }
}

export default BusinessScorecard;
