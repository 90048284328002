import React, { Component } from "react";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import Design from "../../../utils/design.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";

class ScheduledCards extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "scheduledPostcardsPerOs",
        path: "api/product/scheduled_cards_per_os",
        params: { format: "postcard" }
      })
      .addRessource({
        name: "scheduledGreetingsPerOs",
        path: "api/product/scheduled_cards_per_os",
        params: { format: "greeting_card" }
      })
      .addRessource({
        name: "scheduledPostcardsPerCategory",
        path: "api/product/scheduled_cards_per_category",
        params: { format: "postcard", language: "fr" }
      })
      .addRessource({
        name: "scheduledGreetingsPerCategory",
        path: "api/product/scheduled_cards_per_category",
        params: { format: "greeting_card", language: "fr" }
      })
      .addRessource({
        name: "scheduledNewVsRegular",
        path: "api/product/scheduled_cards_new_vs_regular"
      })
      .addRessource({
        name: "scheduledPerDelay",
        path: "api/product/scheduled_cards_per_delay"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getMultiLinesChart(ressource, columnToStr = x => x) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let lines = [];
    let total_columns = Math.min(ressource.data.columns.length, 12);
    for (let i = 0; i < total_columns; i++) {
      lines.push({
        name: columnToStr(ressource.data.columns[i]),
        color: Design.getColor(i, total_columns),
        y: ressource.data.rows.map(x => x.values[i])
      });
    }
    let x = ressource.data.rows.map(x => ValueAdaptor.parseUTCDate(x["date"]));
    return (
      <MultiLinesChart
        x={x}
        xToStr={ValueAdaptor.toDDMMYYYY}
        yToStr={ValueAdaptor.toPercent}
        lines={lines}
        hasAxis={true}
        is_log_scale={false}
      />
    );
  }

  render() {
    return (
      <div className="page product scheduled_cards">
        <h1>Scheduled Cards</h1>
        <div className="row">
          <div className="row_item">
            <h3>Scheduled Postcards Per OS [Postcards]</h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledPostcardsPerOs
            )}
          </div>
          <div className="row_item">
            <h3>Scheduled Postcards Per OS [Greetings]</h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledGreetingsPerOs
            )}
          </div>
        </div>
        <div className="row">
          <div className="row_item">
            <h3>Scheduled Postcards Per FR Category [Postcards]</h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledPostcardsPerCategory
            )}
          </div>
          <div className="row_item">
            <h3>Scheduled Postcards Per FR Category [Greetings]</h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledGreetingsPerCategory
            )}
          </div>
        </div>
        <div className="row">
          <div className="row_item">
            <h3>
              Scheduled Cards : New User Vs Regular (366 days+ after activation)
            </h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledNewVsRegular,
              x => (x ? "regular user" : "new user")
            )}
          </div>
          <div className="row_item">
            <h3>Scheduled Cards Per Delay</h3>
            {this.getMultiLinesChart(
              this.state.get().ressources.scheduledPerDelay
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduledCards;
