import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class CreditsPerProduct extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "creditsPerProduct",
      path: "api/finance/credits_per_product"
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getColumns() {
    let cols = [
      {
        name: "year",
        mapper: x => ValueAdaptor.toYYYY(x.date),
        isHeader: true
      },
      {
        name: "month",
        mapper: x => ValueAdaptor.toMonthName(x.date),
        isHeader: true
      }
    ];
    for (let product of [
      "postcard",
      "postcard_magnet",
      "square_card_magnet",
      "greeting",
      "set_of_postcards",
      "set_of_greetings",
      "square_photobook",
      "gazette",
      "postcard_video"
    ]) {
      cols.push({
        name: `credits (${product})`,
        mapper: x => ValueAdaptor.toAutoUnit(x[`${product}_credits`])
      });
      cols.push({
        name: `units (${product})`,
        mapper: x => ValueAdaptor.toAutoUnit(x[`${product}_units`])
      });
      cols.push({
        name: `credits/units (${product})`,
        mapper: x =>
          x[`${product}_units`] == 0
            ? "-"
            : ValueAdaptor.toAutoUnit(
                x[`${product}_credits`] / x[`${product}_units`]
              )
      });
    }
    return cols;
  }

  render() {
    if (!this.state.get().ressources.creditsPerProduct.isLoaded) {
      return (
        <div className="page finance credits_per_product">
          <Loader />
        </div>
      );
    }
    return (
      <div className="page finance credits_per_product">
        <h1>Credits Per Product</h1>
        <Table
          data={this.state.get().ressources.creditsPerProduct.data}
          cols={this.getColumns()}
        />
      </div>
    );
  }
}

export default CreditsPerProduct;
