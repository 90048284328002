import React, { Component } from "react";
import PropTypes from "prop-types";

class ItemList extends Component {
  constructor(props) {
    super(props);
  }

  render = () => (
    <>
      {this.props.data.map((entry, i) => (
        <div key={i} className="item">
          {this.props.mapper(entry)}
        </div>
      ))}
    </>
  );
}

ItemList.propTypes = {
  mapper: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default ItemList;
