import React, { Component } from "react";
import SideBar from "./elements/utils/SideBar.js";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import DescriptionIcon from "@material-ui/icons/Description";
import ColorizeIcon from "@material-ui/icons/Colorize";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import BuildIcon from "@material-ui/icons/Build";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import BusinessScorecard from "./pages/business/BusinessScorecard.js";
import KpiOverview from "./pages/business/KpiOverview.js";
import Subscriptions from "./pages/business/Subscriptions.js";
import B2B from "./pages/business/B2B.js";
import General from "./pages/business/General.js";

import PaidMedia from "./pages/acquisition/PaidMedia.js";
import Partnerships from "./pages/acquisition/Partnerships.js";
import Activations from "./pages/acquisition/Activations.js";

import PostcardLayouts from "./pages/brandcontent/PostcardLayouts.js";
import BookLayouts from "./pages/brandcontent/BookLayouts.js";
import Themes from "./pages/brandcontent/Themes.js";
import OverviewPrintouts from "./pages/brandcontent/OverviewPrintouts.js";
import LatestDesigns from "./pages/brandcontent/LatestDesigns.js";
import CardSides from "./pages/brandcontent/CardSides.js";

import ScheduledCards from "./pages/product/ScheduledCards.js";
import AppAndDevices from "./pages/product/AppAndDevices.js";
import Contacts from "./pages/product/Contacts.js";
import Care from "./pages/product/Care.js";
import PaymentFlow from "./pages/product/PaymentFlow.js";

import CreditsPerProduct from "./pages/finance/CreditsPerProduct.js";
import CreditAccounting from "./pages/finance/CreditAccounting.js";
import SubscriptionRevenue from "./pages/finance/SubscriptionRevenue.js";
import RemainingCredits from "./pages/finance/RemainingCredits.js";
import Costs from "./pages/finance/Costs.js";

import DatabaseHealth from "./pages/DatabaseHealth.js";

import NotFound from "./pages/NotFound.js";

class Content extends Component {
  constructor(props) {
    super(props);
    this.items = [
      {
        label: "Business",
        icon: <LocalGroceryStoreIcon className="menu_icon" />,
        childs: [
          {
            label: "KPI Overview",
            path: "/business/kpi_overview"
          },
          {
            label: "Subscriptions",
            path: "/business/subscriptions"
          },
          {
            label: "General",
            path: "/business/general"
          },
          {
            label: "Scorecard",
            path: "/business/scorecard"
          },
          {
            label: "B2B",
            path: "/business/b2b"
          }
        ]
      },
      {
        label: "Acquisition",
        icon: <SettingsInputAntennaIcon className="menu_icon" />,
        childs: [
          {
            label: "Partnerships",
            path: "/acquisition/partnerships"
          },
          {
            label: "Paid Media",
            path: "/acquisition/paid_media"
          },
          {
            label: "Activations",
            path: "/acquisition/activations"
          }
        ]
      },
      {
        label: "Brand & Content",
        icon: <ColorizeIcon className="menu_icon" />,
        childs: [
          {
            label: "Themes",
            path: "/brand_and_content/themes"
          },
          {
            label: "Postcard Layouts",
            path: "/brand_and_content/postcard_layouts"
          },
          {
            label: "Book Layouts",
            path: "/brand_and_content/book_layouts"
          },
          {
            label: "Overview Printouts",
            path: "/brand_and_content/overview_printouts"
          },
          {
            label: "Latest Designs",
            path: "/brand_and_content/latest_designs"
          },
          {
            label: "Card Sides",
            path: "/brand_and_content/card_sides"
          }
        ]
      },
      {
        label: "Product",
        icon: <BuildIcon className="menu_icon" />,
        childs: [
          {
            label: "App & Devices",
            path: "/product/app_and_devices"
          },
          {
            label: "Scheduled Cards",
            path: "/product/scheduled_cards"
          },
          {
            label: "Payment Flow",
            path: "/product/payment_flow"
          },
          {
            label: "Contacts",
            path: "/product/contacts"
          },
          {
            label: "Care",
            path: "/product/care"
          }
        ]
      },
      {
        label: "Finance",
        icon: <AccountBalanceIcon className="menu_icon" />,
        childs: [
          {
            label: "Subscription Revenue",
            path: "/finance/subscription_revenue"
          },
          {
            label: "Credit Accounting",
            path: "/finance/credit_accounting"
          },
          {
            label: "Credits Per Product",
            path: "/finance/credits_per_product"
          },
          {
            label: "Remaining Credits",
            path: "/finance/remaining_credits"
          },
          {
            label: "Costs",
            path: "/finance/costs"
          }
        ]
      },
      {
        path: "/database_health",
        label: "Data Health",
        icon: <LocalHospitalIcon className="menu_icon" />
      },
      {
        path: "/admin_users/sign_out",
        label: "Logout",
        icon: <PowerSettingsNewIcon className="menu_icon" />,
        dataMethod: "delete"
      }
    ];
  }

  render() {
    return (
      <Router>
        <div className="app">
          <SideBar items={this.items} />
          <Switch>
            <Route exact path="/" component={KpiOverview} />
            <Route
              exact
              path="/brand_and_content/overview_printouts"
              component={OverviewPrintouts}
            />
            <Route exact path="/brand_and_content/themes" component={Themes} />
            <Route exact path="/business/general" component={General} />
            <Route
              exact
              path="/business/kpi_overview"
              component={KpiOverview}
            />
            <Route
              exact
              path="/business/subscriptions"
              component={Subscriptions}
            />
            <Route
              exact
              path="/brand_and_content/latest_designs"
              component={LatestDesigns}
            />
            <Route
              exact
              path="/brand_and_content/postcard_layouts"
              component={PostcardLayouts}
            />
            <Route
              exact
              path="/brand_and_content/book_layouts"
              component={BookLayouts}
            />
            <Route
              exact
              path="/brand_and_content/card_sides"
              component={CardSides}
            />
            <Route exact path="/acquisition/paid_media" component={PaidMedia} />
            <Route
              exact
              path="/acquisition/partnerships"
              component={Partnerships}
            />
            <Route
              exact
              path="/acquisition/activations"
              component={Activations}
            />
            <Route
              exact
              path="/business/scorecard"
              component={BusinessScorecard}
            />
            <Route exact path="/business/b2b" component={B2B} />
            <Route
              exact
              path="/product/scheduled_cards"
              component={ScheduledCards}
            />
            <Route exact path="/product/contacts" component={Contacts} />
            <Route
              exact
              path="/product/app_and_devices"
              component={AppAndDevices}
            />
            <Route exact path="/product/care" component={Care} />
            <Route exact path="/product/payment_flow" component={PaymentFlow} />
            <Route
              exact
              path="/finance/credits_per_product"
              component={CreditsPerProduct}
            />
            <Route
              exact
              path="/finance/credit_accounting"
              component={CreditAccounting}
            />
            <Route
              exact
              path="/finance/subscription_revenue"
              component={SubscriptionRevenue}
            />
            <Route
              exact
              path="/finance/remaining_credits"
              component={RemainingCredits}
            />
            <Route exact path="/finance/costs" component={Costs} />
            <Route exact path="/database_health" component={DatabaseHealth} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Content;
