import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class CreditsAccounting extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "creditAccounting",
      path: "api/finance/credit_accounting"
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getCreditAccountingColumns() {
    let cols = [
      {
        name: "year",
        mapper: x => ValueAdaptor.toYYYY(x.date),
        isHeader: true
      },
      {
        name: "month",
        mapper: x => ValueAdaptor.toMonthName(x.date),
        isHeader: true
      },
      {
        name: "referrals",
        mapper: x =>
          parseFloat(x["coupon_referral_sponsor"]) + parseFloat(x["coupon_referral_sponsorised"])
      },
      {
        name: "purchased",
        mapper: x => parseFloat(x["purchased"])
      },
      {
        name: "used",
        mapper: x => parseFloat(x["used"])
      },
      {
        name: "adjustments",
        mapper: x => parseFloat(x["admin_adjustment"])
      },
      {
        name: "gift",
        mapper: x => parseFloat(x["coupon_gift"])
      },
      {
        name: "discovery",
        mapper: x => parseFloat(x["coupon_discovery"])
      },
      {
        name: "balance",
        mapper: x =>
          parseFloat(x["purchased"]) +
          parseFloat(x["coupon_referral_sponsor"]) +
          parseFloat(x["coupon_referral_sponsorised"]) -
          parseFloat(x["used"]) +
          parseFloat(x["admin_adjustment"]) +
          parseFloat(x["coupon_gift"]) +
          parseFloat(x["coupon_discovery"])
      },
      {
        name: "remaining",
        mapper: x => parseFloat(x["remaining_credits"])
      },
      {
        name: "%[error]",
        mapper: x => ValueAdaptor.toPercent(parseFloat(x["error_rate"]))
      }
    ];
    return cols;
  }

  render() {
    let creditAccountingTable = <Loader />;
    if (this.state.get().ressources.creditAccounting.isLoaded) {
      creditAccountingTable = (
        <Table
          data={this.state.get().ressources.creditAccounting.data}
          cols={this.getCreditAccountingColumns()}
        />
      );
    }

    return (
      <div className="page finance credit_accounting">
        <h1>Credit Accounting</h1>
        {creditAccountingTable}
      </div>
    );
  }
}

export default CreditsAccounting;
