import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import DateSelector from "../../elements/selectors/DateSelector.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import ItemSelector from "../../elements/selectors/ItemSelector.js";

class Themes extends Component {
  constructor(params) {
    super(params);

    let {
      originalFromDate,
      originalUntilDate,
      baselineFromDate,
      baselineUntilDate
    } = TimescaleController.defaultPreset();

    this.state = new State(this)
      .addRessource({
        name: "events",
        path: "api/brandcontent/event_printouts",
        params: {
          original_from_date: originalFromDate,
          original_until_date: originalUntilDate,
          baseline_from_date: baselineFromDate,
          baseline_until_date: baselineUntilDate,
          format: "postcard",
          language: "fr",
          is_currently_published: true
        }
      })
      .addRessource({
        name: "themes",
        path: "api/brandcontent/theme_printouts",
        params: {
          from_date: originalFromDate,
          until_date: originalUntilDate,
          event_id: 556,
          format: "postcard",
          is_currently_published: true
        }
      })
      .add("selectedEventName", "Une photo");
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  update_data = x => {
    this.state.set(s => {
      s.ressources.events.params.baseline_from_date = x.baselineFromDate;
      s.ressources.events.params.baseline_until_date = x.baselineUntilDate;
      s.ressources.events.params.original_from_date = x.originalFromDate;
      s.ressources.events.params.original_until_date = x.originalUntilDate;
      s.ressources.events.params.language = x.language;
      s.ressources.events.params.is_currently_published =
        x.isCurrentlyPublished;
      s.ressources.events.params.format = x.format;
      s.ressources.themes.params.from_date = x.originalFromDate;
      s.ressources.themes.params.until_date = x.originalUntilDate;
      s.ressources.themes.params.is_currently_published =
        x.isCurrentlyPublished;
      s.ressources.themes.params.format = x.format;
    });
  };

  eventsColumns = () => [
    { name: "Category", mapper: x => x["category_name"], isHeader: true },
    {
      name: "Total",
      mapper: x => (
        <div key={x["category_name"]}>
          <span className="original">
            {ValueAdaptor.toAutoUnit(x["original_total_in_category"])}
          </span>
          <span className="baseline">
            {ValueAdaptor.toAutoUnit(x["baseline_total_in_category"])}
          </span>
        </div>
      ),
      isHeader: true
    },
    {
      name: "%",
      mapper: x => (
        <div key={x.category_name}>
          <span className="original">
            {ValueAdaptor.toPercent(
              x["original_total_in_category"] / x["original_total_overall"]
            )}
          </span>
          <span className="baseline">
            {ValueAdaptor.toPercent(
              x["baseline_total_in_category"] / x["baseline_total_overall"]
            )}
          </span>
        </div>
      ),
      isHeader: true
    },
    {
      name: "event",
      mapper: x => (
        <button
          type="button"
          className="clickable"
          onClick={() => {
            this.state.set(s => {
              s.selectedEventName = x["event_name"];
              s.ressources.themes.params.event_id = x["event_id"];
            });
          }}
        >
          {x["event_name"]}
        </button>
      ),
      isHeader: false
    },
    {
      name: "Total",
      mapper: x => (
        <div key={x["event_name"]}>
          <span className="original">
            {ValueAdaptor.toAutoUnit(x["original_total_in_event"])}
          </span>
          <span className="baseline">
            {ValueAdaptor.toAutoUnit(x["baseline_total_in_event"])}
          </span>
        </div>
      ),
      isHeader: false
    },
    {
      name: "%",
      mapper: x => (
        <div key={x["event_name"]}>
          <span className="original">
            {ValueAdaptor.toPercent(
              x["original_total_in_event"] / x["original_total_in_category"]
            )}
          </span>
          <span className="baseline">
            {ValueAdaptor.toPercent(
              x["baseline_total_in_event"] / x["baseline_total_in_category"]
            )}
          </span>
        </div>
      ),
      isHeader: false
    }
  ];

  themesColumns = () => [
    {
      mapper: x => (
        <img src={x.file_path} alt="theme_preview" className="theme_preview" />
      )
    },
    { mapper: x => `#${x["theme_id"]}` },
    { mapper: x => (x["position"] ? `n°${x["position"]}` : "-") },
    {
      mapper: x => ValueAdaptor.toAutoUnit(x["total_in_theme"])
    },
    {
      mapper: x =>
        ValueAdaptor.toPercent(x["total_in_theme"] / x["total_in_event"])
    }
  ];

  getEvents = () => (
    <Table
      data={this.state.get().ressources.events.data}
      cols={this.eventsColumns()}
    />
  );

  getThemes = () => (
    <Table
      data={this.state.get().ressources.themes.data}
      cols={this.themesColumns()}
    />
  );

  getLoader = () => <Loader />;

  render() {
    return (
      <div className="page brandcontent card_printouts">
        <Controller callback={this.update_data} />
        <div className="categories">
          {this.state.get().ressources.events.isLoaded
            ? this.getEvents()
            : this.getLoader()}
        </div>
        {this.state.selectedEventName !== null && (
          <div className="themes">
            <h2>{this.state.get().selectedEventName}</h2>
            {this.state.get().ressources.themes.isLoaded
              ? this.getThemes()
              : this.getLoader()}
          </div>
        )}
      </div>
    );
  }
}

export default Themes;

class Controller extends Component {
  constructor(props) {
    super(props);

    let {
      originalFromDate,
      originalUntilDate,
      baselineFromDate,
      baselineUntilDate
    } = TimescaleController.defaultPreset();

    this.state = {
      isCurrentlyPublished: true,
      format: "postcard",
      language: "fr",
      originalFromDate: originalFromDate,
      originalUntilDate: originalUntilDate,
      baselineFromDate: baselineFromDate,
      baselineUntilDate: baselineUntilDate
    };
  }

  getButton(name, var_name, value) {
    return (
      <button
        className={
          this.state[var_name] == value ? "active_button" : "inactive_button"
        }
        onClick={() => {
          this.setState({ [var_name]: value });
        }}
      >
        {name}
      </button>
    );
  }

  render() {
    return (
      <div className="controler row_direction space_between">
        <div className="selector published col_direction space_evenly">
          {this.getButton("All", "isCurrentlyPublished", false)}
          {this.getButton("Published", "isCurrentlyPublished", true)}
        </div>

        <TimescaleController
          callback={x => {
            this.setState({
              originalFromDate: x["originalFromDate"],
              originalUntilDate: x["originalUntilDate"],
              baselineFromDate: x["baselineFromDate"],
              baselineUntilDate: x["baselineUntilDate"],
              displayType: x["displayType"]
            });
          }}
        />

        <div className="selector format col_direction space_evenly">
          <ItemSelector
            items={{
              Postcards: "postcard",
              "Postcards Magnet": "postcard_magnet",
              "Square Cards Magnet": "square_premium_card_magnet",
              "Square Cards": "square_premium_card_magnet",
              Greetings: "greeting_card",
              "Postcards [SET]": "set_of_postcards",
              "Greetings [SET]": "set_of_greeting_cards",
              "Postcard Video": "postcard_video",
              Portrait: "portrait_card"
            }}
            defaultLabel="Postcards"
            callback={x => {
              this.setState({ format: x });
            }}
          />
        </div>

        <div className="selector language col_direction space_evenly">
          <div className="row_direction space_between">
            {this.getButton("FR", "language", "fr")}
            {this.getButton("ES", "language", "es")}
            {this.getButton("EN", "language", "en")}
          </div>
          <div className="row_direction space_between">
            {this.getButton("PT", "language", "pt")}
            {this.getButton("IT", "language", "it")}
            {this.getButton("DE", "language", "de")}
          </div>
        </div>

        <div className="selector date col_direction space_evenly">
          <div className="original row_direction space_evenly">
            Original:
            <DateSelector
              defaultDate={this.state.originalFromDate}
              callback={x => {
                this.setState({ originalFromDate: x });
              }}
            />
            until
            <DateSelector
              defaultDate={this.state.originalUntilDate}
              callback={x => {
                this.setState({ originalUntilDate: x });
              }}
            />
          </div>
          <div className="selector baseline row_direction space_evenly">
            Baseline:
            <DateSelector
              defaultDate={this.state.baselineFromDate}
              callback={x => {
                this.setState({ baselineFromDate: x });
              }}
            />
            until
            <DateSelector
              defaultDate={this.state.baselineUntilDate}
              callback={x => {
                this.setState({ baselineUntilDate: x });
              }}
            />
          </div>
        </div>

        <div className="selector refresh">
          <button
            className="active_button"
            onClick={() => {
              this.props.callback(this.state);
            }}
          >
            Refresh
          </button>
        </div>
      </div>
    );
  }
}

class TimescaleController extends Component {
  constructor(props) {
    super(props);
    this.state = TimescaleController.defaultPreset();
    this.currentPreset = TimescaleController.defaultPreset();
  }

  static defaultPreset() {
    return TimescaleController.timePreset("last_7days_vs_last_year");
  }

  static timePreset(preset_agg_date) {
    let today = ValueAdaptor.utcToday();
    let tomorrow = ValueAdaptor.addDays(today, 1);
    let prevYear = ValueAdaptor.addYears(today, -1);
    let yesterday = ValueAdaptor.addDays(today, -1);
    let beginningOfMonth = ValueAdaptor.truncateUTCMonthDate(yesterday);
    let prevMonth = ValueAdaptor.addMonths(beginningOfMonth, -1);

    switch (preset_agg_date) {
      case "today_vs_last_year":
        let preset = {
          activePreset: "today_vs_last_year",
          originalFromDate: today,
          originalUntilDate: tomorrow
        };
        preset["baselineFromDate"] = prevYear;
        preset["baselineUntilDate"] = ValueAdaptor.addYears(tomorrow, -1);
        return preset;

      case "last_7days_vs_last_year":
        preset = {
          activePreset: "last_7days_vs_last_year",
          originalFromDate: ValueAdaptor.addDays(today, -7),
          originalUntilDate: today
        };
        preset["baselineFromDate"] = ValueAdaptor.addDays(prevYear, -7);
        preset["baselineUntilDate"] = prevYear;
        return preset;

      case "curr_month_vs_last_year":
        let fromDate = beginningOfMonth;
        let untilDate = today;
        let baselineFromDate = ValueAdaptor.addYears(beginningOfMonth, -1);
        let baselineUntilDate = ValueAdaptor.addYears(today, -1);

        preset = {
          activePreset: "curr_month_vs_last_year",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: baselineFromDate,
          baselineUntilDate: baselineUntilDate
        };

        return preset;

      case "prev_month_vs_last_year":
        fromDate = prevMonth;
        untilDate = beginningOfMonth;
        baselineFromDate = ValueAdaptor.addYears(prevMonth, -1);
        baselineUntilDate = ValueAdaptor.addYears(beginningOfMonth, -1);

        preset = {
          activePreset: "curr_month_vs_last_year",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: baselineFromDate,
          baselineUntilDate: baselineUntilDate
        };

        return preset;

      case "last_five_years_vs_last_year":
        fromDate = ValueAdaptor.addYears(
          ValueAdaptor.truncateUTCQuarterDate(yesterday),
          -5
        );
        untilDate = ValueAdaptor.truncateUTCQuarterDate(yesterday);
        preset = {
          activePreset: "last_five_year_vs_last_year",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: ValueAdaptor.addYears(fromDate, -1),
          baselineUntilDate: ValueAdaptor.addYears(untilDate, -1)
        };

        return preset;

      case "curr_year_vs_last_year":
        fromDate = ValueAdaptor.truncateUTCYearDate(yesterday);
        untilDate = today;

        preset = {
          activePreset: "curr_year_vs_last_year",
          originalFromDate: fromDate,
          originalUntilDate: untilDate,
          baselineFromDate: ValueAdaptor.addYears(fromDate, -1),
          baselineUntilDate: ValueAdaptor.addYears(untilDate, -1)
        };

        return preset;

      default:
        return {};
    }
  }

  getButton(name, value) {
    let callback = () => {
      let preset = TimescaleController.timePreset(value);
      let newPreset = {
        activePreset: value,
        originalFromDate: preset.originalFromDate,
        originalUntilDate: preset.originalUntilDate,
        baselineFromDate: preset.baselineFromDate,
        baselineUntilDate: preset.baselineUntilDate
      };
      this.currentPreset = newPreset;
      this.setState(newPreset);

      this.props.callback(this.currentPreset);
    };

    let isActive =
      this.state.activePreset == value ? "active_button" : "inactive_button";

    return (
      <button className={isActive} onClick={callback}>
        {name}
      </button>
    );
  }

  render() {
    return (
      <div className="timescale-controller row_direction space_between">
        <div className="selector preset col_direction space_evenly">
          <div className="row_direction space_between">
            {this.getButton("Today", "today_vs_last_year")}
            {this.getButton("Last 7 days", "last_7days_vs_last_year")}
            {this.getButton(
              `${ValueAdaptor.toMonthName(
                ValueAdaptor.addMonths(
                  ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1),
                  -1
                )
              )}`,
              "prev_month_vs_last_year"
            )}
          </div>
          <div className="row_direction space_between">
            {this.getButton(
              `${ValueAdaptor.toMonthName(
                ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1)
              )}`,
              "curr_month_vs_last_year"
            )}
            {this.getButton(
              `${ValueAdaptor.toYYYY(
                ValueAdaptor.addDays(ValueAdaptor.utcToday(), -1)
              )}`,
              "curr_year_vs_last_year"
            )}
            {this.getButton("Last 5 Years", "last_five_years_vs_last_year")}
          </div>
        </div>
      </div>
    );
  }
}
