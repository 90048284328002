import moment from "moment";

class ValueAdaptor {
  static monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  static truncateUTCDateTo(date, agg_type) {
    switch (agg_type) {
      case "hour":
        return ValueAdaptor.truncateUTCHourDate(date);
      case "day":
        return ValueAdaptor.truncateUTCDate(date);
      case "week":
        return ValueAdaptor.truncateUTCWeekDate(date);
      case "month":
        return ValueAdaptor.truncateUTCMonthDate(date);
      case "year":
        return ValueAdaptor.truncateUTCYearDate(date);
      default:
        return ValueAdaptor.truncateUTCDate(date);
    }
  }

  static utcNow() {
    let date = new Date();

    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    );
  }

  static utcToday() {
    let date = new Date();
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );
  }

  static truncateUTCHourDate(date) {
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    date.setUTCMilliseconds(0);
    return date;
  }

  static truncateUTCDate(date) {
    ValueAdaptor.truncateUTCHourDate(date);
    date.setUTCHours(0);
    return date;
  }

  static truncateUTCWeekDate(date) {
    ValueAdaptor.truncateUTCDate(date);
    let dow = (date.getUTCDay() == 0 ? 7 : date.getUTCDay()) - 1;
    let dom = date.getUTCDate() - dow;
    date.setUTCDate(dom);
    return date;
  }

  static truncateUTCMonthDate(date) {
    ValueAdaptor.truncateUTCDate(date);
    date.setUTCDate(1);
    return date;
  }

  static truncateUTCQuarterDate(date) {
    ValueAdaptor.truncateUTCDate(date);
    date.setUTCDate(1);
    date.setUTCMonth(date.getUTCMonth() - (date.getUTCMonth() % 3));
    return date;
  }

  static truncateUTCYearDate(date) {
    ValueAdaptor.truncateUTCMonthDate(date);
    date.setUTCMonth(0);
    return date;
  }

  static parseUTCDate(date) {
    if (!(date instanceof Date)) {
      return moment.utc(date, "YYYY-MM-DD HH:mm:ss").toDate();
    }
    return date;
  }

  static toMonthName(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return ValueAdaptor.monthNames[date.getUTCMonth()];
  }

  static toDay(date) {
    return ValueAdaptor.parseUTCDate(date).getUTCDate();
  }

  static toLiteralDate(date) {
    return `${ValueAdaptor.toDay(date)} ${ValueAdaptor.toMonthName(
      date
    )} ${ValueAdaptor.toYYYY(date)}`;
  }

  static toDDMMYY(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${("" + date.getUTCDate()).padStart(2, "0")}/${(
      "" +
      (date.getUTCMonth() + 1)
    ).padStart(2, "0")}/${("" + date.getUTCFullYear()).slice(2)}`;
  }

  static toYYYYMMDD(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${date.getUTCFullYear()}-${("" + (date.getUTCMonth() + 1)).padStart(
      2,
      "0"
    )}-${("" + date.getUTCDate()).padStart(2, "0")}`;
  }

  static toMMYYYY(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${("" + (date.getUTCMonth() + 1)).padStart(
      2,
      "0"
    )}/${date.getUTCFullYear()}`;
  }

  static toDDMMYYYY(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${("" + date.getUTCDate()).padStart(2, "0")}/${(
      "" +
      (date.getUTCMonth() + 1)
    ).padStart(2, "0")}/${date.getUTCFullYear()}`;
  }

  static toYYYY(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${date.getUTCFullYear()}`;
  }

  static toDDMM(date) {
    date = ValueAdaptor.parseUTCDate(date);
    return `${("" + date.getUTCDate()).padStart(2, "0")}/${(
      "" +
      (date.getUTCMonth() + 1)
    ).padStart(2, "0")}`;
  }

  static toFloat(value) {
    if (typeof value === "string") {
      return parseFloat(value);
    }
    return value;
  }

  static toPercent(rate, infinityThreshold = 1000) {
    if (Number.isNaN(rate) || rate === undefined) {
      return "-%";
    }
    if (rate == "Infinity" || rate * 100 >= infinityThreshold) {
      return "∞%";
    }
    return `${(ValueAdaptor.toFloat(rate) * 100).toFixed(1)}%`;
  }

  static toKilo(value, symbol, precision = undefined) {
    return `${(ValueAdaptor.toFloat(value) / 1000).toFixed(
      precision || 1
    )}K${symbol}`;
  }

  static toMega(value, symbol, precision = undefined) {
    return `${(ValueAdaptor.toFloat(value) / 1000000).toFixed(
      precision || 1
    )}M${symbol}`;
  }

  static toGiga(value, symbol, precision = undefined) {
    return `${(ValueAdaptor.toFloat(value) / 1000000000).toFixed(
      precision || 1
    )}G${symbol}`;
  }

  static toAutoUnit(value, symbol, precision = undefined) {
    if (Number.isNaN(value) || value === undefined) {
      return "-";
    }

    if (value == "Infinity") {
      return `∞${symbol}`;
    }

    value = ValueAdaptor.toFloat(value);

    symbol = symbol || "";
    if (value == 0) {
      return `${value}${symbol}`;
    }
    if (value < 1) {
      return `${value.toFixed(precision || 2)}${symbol}`;
    }
    if (value < 100) {
      return `${value.toFixed(precision || 1)}${symbol}`;
    }
    if (value < 1000) {
      return `${value.toFixed(precision || 0)}${symbol}`;
    }
    if (value < 1000000) {
      return ValueAdaptor.toKilo(value, symbol, precision);
    }
    if (value < 1000000000) {
      return ValueAdaptor.toMega(value, symbol, precision);
    }

    return ValueAdaptor.toGiga(value, symbol, precision);
  }

  static addDays(date, totalDays) {
    date = new Date(date.getTime());
    date.setUTCDate(date.getUTCDate() + totalDays);
    return date;
  }

  static addMonths(date, totalMonths) {
    date = new Date(date.getTime());
    date.setUTCMonth(date.getUTCMonth() + totalMonths);
    return date;
  }

  static addYears(date, totalYears) {
    date = new Date(date.getTime());
    date.setUTCFullYear(date.getUTCFullYear() + totalYears);
    return date;
  }

  static daysBetween(fromDate, toDate) {
    return Math.ceil((toDate - fromDate) / (1000 * 3600 * 24));
  }

  static toGrowth(curr, prev) {
    if ((curr - prev) / Math.max(prev, 0.000000001) > 99) {
      return "∞";
    }
    return ValueAdaptor.toPercent((curr - prev) / prev);
  }

  static toGrowthValue(curr, prev) {
    if (prev === undefined || prev == 0 || curr === undefined || curr == 0) {
      return 0;
    }
    return (curr - prev) / prev;
  }

  static shortString(str, maxLength) {
    if (str.length <= maxLength || maxLength < 4) {
      return str;
    }
    return `${str.slice(0, maxLength / 2 - 1)}..${str.slice(
      str.length - maxLength / 2 + 1
    )}`;
  }
}

export default ValueAdaptor;
