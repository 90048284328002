import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptator from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import ItemSelector from "../../elements/selectors/ItemSelector.js";

class Partnerships extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "partnerships",
      path: "api/acquisition/partnerships",
      params: { segment: "all_influencers" }
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  influencerColumns = () => [
    {
      name: "coupon id",
      mapper: x => x.coupon_id,
      isHeader: true
    },
    { name: "name", mapper: x => x.name, isHeader: true },
    { name: "code", mapper: x => x.code || "-", isHeader: true },
    {
      name: "starts at",
      mapper: x => ValueAdaptator.toDDMMYY(x.starts_at)
    },
    {
      name: "expires at",
      mapper: x => ValueAdaptator.toDDMMYY(x.expires_at)
    },
    { name: "€ (on day)", mapper: x => `${x.revenue}€` },
    { name: "€ (7days)", mapper: x => `${x.revenue_7days}€` },
    { name: "€ (30days)", mapper: x => `${x.revenue_30days}€` },
    { name: "€ (365days)", mapper: x => `${x.revenue_365days}€` },
    { name: "new € (30days)", mapper: x => `${x.new_revenue_30days}€` },
    { name: "repeat € (30days)", mapper: x => `${x.repeat_revenue_30days}€` },
    { name: "not used €", mapper: x => `${x.not_used_revenue}€` },
    { name: "coupon added", mapper: x => x.coupon_added },
    { name: "coupons used", mapper: x => x.coupon_used },
    {
      name: "% fake accounts",
      mapper: x => ValueAdaptator.toPercent(x.fake_account_rate)
    },
    {
      name: "% new registers",
      mapper: x => ValueAdaptator.toPercent(x.new_register_rate)
    },
    { name: "new registers", mapper: x => x.new_registers },
    {
      name: "new activated registers ",
      mapper: x => x.new_activated_registers
    },
    {
      name: "% activation",
      mapper: x => ValueAdaptator.toPercent(x.activation_rate)
    },
    { name: "new clients", mapper: x => x.new_clients }
  ];

  render() {
    let table = <Loader />;
    if (this.state.get().ressources.partnerships.isLoaded) {
      table = (
        <Table
          data={this.state.get().ressources.partnerships.data}
          cols={this.influencerColumns()}
        />
      );
    }
    return (
      <div className="page acquisition partnerships">
        <ItemSelector
          items={{
            "All Influencers": "all_influencers",
            "Micro Influencers": "micro_influencers",
            "Macro Influencers": "macro_influencers",
            Campaign: "campaign",
            Emailing: "emailing",
            Partnership: "partnership",
            Discount: "discount",
            Discovery: "discovery"
          }}
          defaultLabel="All Influencers"
          callback={x => {
            this.state.set(s => {
              s.ressources.partnerships.params.segment = x;
            });
          }}
        />
        <div className="row data_specifications">
          <div className="row_item">
            <i>
              All revenues shown include VAT. Foreign currencies are converted
              at a ratio of 1:1. Fake accounts are filtered for columns: "New
              Register Rate", "New Registers", "New Activated Registers",
              "Activation Rate" and "New Clients".
              <a
                className="download_link"
                href={`../api/acquisition/partnerships?segment=${
                  this.state.get().ressources.partnerships.params.segment
                }&type=csv`}
                download
              >
                Download as CSV
              </a>
            </i>
          </div>
          <div className="row_item definition">
            <div className="content">
              <strong>Coupon Id</strong> Number identifying the coupon
              <br />
              <strong>Name</strong>Coupon Name
              <br />
              <strong>Starts At</strong> The start date of the operation
              <br />
              <strong>Expires At</strong> The end date of the operation
              <br />
              <strong>€ (On Day)</strong> Revenue generated at the date the code
              is added or consumed (only consumed coupons)
              <br />
              <strong>€ (7 Days)</strong> Revenue generated during the 7 days
              after the code is added (only consumed coupons)
              <br />
              <strong>€ (30 Days)</strong> Revenue generated during the 30 days
              after the code is added (only consumed coupons)
              <br />
              <strong>€ (365 Days)</strong> Revenue generated during the 365
              days after the code is added (only consumed coupons)
              <br />
              <strong>New € (30days)</strong> € generated by users that
              subscribed after the start of the operation <br /> during the 30
              days following the insertion of the coupon
              <br />
              <strong>Repeat € (30days)</strong> € generated by users that
              subscribed before the start of the operation <br /> during the 30
              days following the insertion of the coupon
              <br />
              <strong>Not Used €</strong> Revenue generated by coupons not
              consumed at the date the code is added
              <br />
              <strong>Coupons Added</strong> Total number of codes entered
              <br />
              <strong>Coupons Used</strong> Total number of codes consumed
              <br />
              <strong>% Fake Accounts</strong> Percentage of coupons consumed by
              fake accounts
              <br />
              <strong>% New Registers</strong> Percentage of coupons consumed by
              users who subscribed after the beginning of the operation
              <br />
              <strong>New Registers</strong> Total number of coupons consumed by
              users who subscribed after the beginning of the operation
              <br />
              <strong>New Activated Registers</strong> New registers who have
              made at least one payment within 30 days after adding the code.
              <br />
              <strong>% Activation</strong> New activated registers divided by
              new registers
              <br />
              <strong>New Clients</strong> Activations after the start of the
              operation and within 30 days after adding the code
            </div>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

export default Partnerships;
