import React, { Component } from "react";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Design from "../../../utils/design.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";

class Subscriptions extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "activeFamilySpaces",
        path: "api/subscriptions/active_family_spaces"
      })
      .addRessource({
        name: "familySpaceActivationFlow",
        path: "api/subscriptions/family_space_activation_flow"
      })
      .addRessource({
        name: "postsPerFamily",
        path: "api/subscriptions/posts_per_family"
      })
      .addRessource({
        name: "familySpaceNoGazetteRate",
        path: "api/subscriptions/family_space_no_gazette_rate"
      })
      .addRessource({
        name: "activeSubscriptionRate",
        path: "api/subscriptions/active_subscription_rate"
      })
      .addRessource({
        name: "familySpaces",
        path: "api/subscriptions/family_spaces"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getSimpleChart(ressource, yToStr = x => ValueAdaptor.toAutoUnit(x)) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(e => e.value);
    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Total"
        y={y}
        is_log_scale={false}
        yToStr={yToStr}
      />
    );
  }

  getMultilinesChart(
    labels,
    ressource,
    name,
    xCol = "day",
    xMapper = x => x,
    xToStr = x => x
  ) {
    if (!ressource.isLoaded) {
      return (
        <div className="multi_lines_chart">
          <Loader />
        </div>
      );
    }

    let lines = [];
    for (let i = 0; i < labels.length; i++) {
      lines.push({
        name: labels[i].name,
        color: labels[i].color,
        y: ressource.data
          .filter(x => x[labels[i].col] != null)
          .map(x => new Number(x[labels[i].col]))
      });
    }

    let x = ressource.data.map(x => xMapper(x[xCol]));

    return (
      <MultiLinesChart
        key={`${name}_${this.state.get().selId}`}
        x={x}
        xToStr={xToStr}
        yToStr={x => (x ? x.toFixed(1) : "-")}
        lines={lines}
        hasAxis={true}
        is_log_scale={false}
        selectedId={this.state.get().selId}
      />
    );
  }

  render() {
    return (
      <div className="page business subscriptions">
        <h1>Family Spaces</h1>
        <div className="row">
          <div className="rowItem">
            <h2>Total Family Spaces</h2>
            <p>
              Total number of existing family spaces with or without
              subscription.
            </p>
            {this.getSimpleChart(this.state.get().ressources.familySpaces)}
          </div>
          <div className="rowItem">
            <h2>Total Family Spaces Actives</h2>
            <p>
              Total number of existing family spaces with an active
              subscription.
            </p>
            {this.getSimpleChart(
              this.state.get().ressources.activeFamilySpaces
            )}
          </div>
        </div>
        <div className="rowItem large">
          <h2>Family Space Activations & Expirations</h2>
          <p>Number of family spaces activated & expired each week.</p>
          {this.getMultilinesChart(
            [
              {
                name: "Activations",
                col: "activations",
                color: Design.getColor(0, 2)
              },
              {
                name: "Expirations",
                col: "expirations",
                color: Design.getColor(1, 2)
              }
            ],
            this.state.get().ressources.familySpaceActivationFlow,
            "family-space-activation-flow",
            "date",
            ValueAdaptor.parseUTCDate,
            ValueAdaptor.toDDMMYY
          )}
        </div>
        <div className="row">
          <div className="rowItem">
            <h2>Family Space - Sending Rate</h2>
            <p>
              Ratio of active family spaces with at least one post in their
              gazette.
            </p>
            {this.getSimpleChart(
              this.state.get().ressources.familySpaceNoGazetteRate,
              ValueAdaptor.toPercent
            )}
          </div>
          <div className="rowItem">
            <h2>Family Space - Activation rate</h2>
            <p>30 days activation rate after family space creation.</p>
            {this.getSimpleChart(
              this.state.get().ressources.activeSubscriptionRate,
              ValueAdaptor.toPercent
            )}
          </div>
        </div>
        <div className="rowItem large">
          <h2>Average Posts Per Family Space</h2>
          <p>
            Evolution of the average number of posts per family space during the
            current month.
          </p>
          {this.getMultilinesChart(
            [
              {
                name: "current",
                col: "current",
                color: Design.getColor(0, 2)
              },
              {
                name: "previous",
                col: "previous",
                color: Design.getBaselineColor()
              }
            ],
            this.state.get().ressources.postsPerFamily,
            "posts-per-family"
          )}
        </div>
      </div>
    );
  }
}

export default Subscriptions;
