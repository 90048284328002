import React, { Component } from "react";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import Table from "../../elements/data/Table.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";
import Design from "../../../utils/design.js";

class AppAndDevices extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "androidDevices",
        path: "api/product/device_popularity",
        params: { os_name: "android" }
      })
      .addRessource({
        name: "iosDevices",
        path: "api/product/device_popularity",
        params: { os_name: "ios" }
      })
      .addRessource({
        name: "iosAppVersions",
        path: "api/product/app_versions",
        params: { os_name: "ios" }
      })
      .addRessource({
        name: "androidAppVersions",
        path: "api/product/app_versions",
        params: { os_name: "android" }
      })
      .addRessource({
        name: "webAppVersions",
        path: "api/product/app_versions",
        params: { os_name: "web" }
      })
      .add("selectedId", undefined);
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  hover = selId => {
    this.state.set(s => {
      s.selectedId = selId;
    });
  };

  getAppVersions(ressource) {
    if (!ressource.isLoaded) {
      return (
        <div className="multi_lines_chart">
          <Loader />
        </div>
      );
    }
    let lines = [];
    for (let i = 0; i < ressource.data.columns.length; i++) {
      if (ressource.data.columns[i]) {
        lines.push({
          name: ressource.data.columns[i],
          color: Design.getColor(i, ressource.data.columns.length),
          y: ressource.data.rows.map(x => new Number(x["values"][i]))
        });
      }
    }
    if (lines.length == 1) {
      lines.push({
        name: "older",
        color: Design.getColor(1, ressource.data.columns.length),
        y: lines[0].y.map(x => 0)
      });
    } else if (lines.length <= 1) {
      return <div className="no-data">no data</div>;
    }
    let x = ressource.data.rows.map(x => ValueAdaptor.parseUTCDate(x["date"]));
    return (
      <MultiLinesChart
        key={`${this.state.get().selectedId}`}
        x={x}
        xToStr={ValueAdaptor.toDDMM}
        yToStr={ValueAdaptor.toPercent}
        lines={lines}
        hasAxis={false}
        is_log_scale={false}
        hover={this.hover}
        selectedId={this.state.get().selectedId}
      />
    );
  }

  getDevices(ressource) {
    if (!ressource.isLoaded) {
      return (
        <div className="data_table">
          <Loader />
        </div>
      );
    }
    let totalPerVersion = ressource.data.rows.reduce(
      (acc, e) => acc.map((v, i) => v + e.values[i]),
      ressource.data.columns.map(x => 0)
    );
    let total = totalPerVersion.reduce((x, y) => x + y, 0);

    let columns = [];
    columns.push({
      name: "os / versions",
      mapper: x => x["support_type"],
      isHeader: true
    });
    columns.push({
      name: "%",
      mapper: x =>
        ValueAdaptor.toPercent(x.values.reduce((a, b) => a + b, 0) / total)
    });

    for (let i = 0; i < Math.min(ressource.data.columns.length, 5); i += 1) {
      let versionRatio = ValueAdaptor.toPercent(totalPerVersion[i] / total);
      columns.push({
        name: `${ressource.data.columns[i]} (${versionRatio})`,
        mapper: x => x.values[i]
      });
    }

    return <Table data={ressource.data.rows.slice(0, 30)} cols={columns} />;
  }

  render() {
    return (
      <div className="page product app_and_devices">
        <h1>App & Devices</h1>
        <p>All data are taken on active users within the last 30 days.</p>
        <div className="row app_versions">
          <div className="row_item">
            <h2>Android App Versions</h2>
            {this.getAppVersions(
              this.state.get().ressources.androidAppVersions
            )}
          </div>
          <div className="row_item">
            <h2>IOS App Versions</h2>
            {this.getAppVersions(this.state.get().ressources.iosAppVersions)}
          </div>
          <div className="row_item">
            <h2>Web App Versions</h2>
            {this.getAppVersions(this.state.get().ressources.webAppVersions)}
          </div>
        </div>
        <div className="row devices">
          <div className="row_item">
            <h2>Android Devices</h2>
            {this.getDevices(this.state.get().ressources.androidDevices)}
          </div>
          <div className="row_item">
            <h2>IOS Devices</h2>
            {this.getDevices(this.state.get().ressources.iosDevices)}
          </div>
        </div>
      </div>
    );
  }
}

export default AppAndDevices;
