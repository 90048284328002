import React, { Component } from "react";
import State from "../../../utils/State.js";
import GrowthLineChart from "../../elements/data/GrowthLineChart.js";
import Design from "../../../utils/design.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Loader from "../../elements/utils/Loader.js";

class Care extends Component {
  constructor(props) {
    super(props);
    this.state = new State(this);
    this.labels = [
      "resend_postcard",
      "information",
      "credit",
      "no_stat",
      "instruction_for_use",
      "congratulations",
      "coupon",
      "account",
      "payment",
      "cancelled_order",
      "suggestion",
      "ios_bug",
      "tech_bug",
      "prod_bug",
      "modify_order",
      "internet_bug",
      "change_adress"
    ];

    this.labels.forEach(x =>
      this.state.addRessource({
        name: x,
        path: "api/product/zendesk_tickets",
        params: { label: x }
      })
    );
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  buildLineChart = (name, ressource) => {

    if (!ressource.isLoaded) {
      return (
        <div key={name}>
          <Loader />
        </div>
      );
    }

    let xValues = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let yValues = ressource.data.map(e => parseFloat(e.value));
    let yBaseline = ressource.data.map(e => parseFloat(e.baseline));

    let yToStr = y =>
      ValueAdaptor.toAutoUnit(y, `${ressource.meta.unit || ""}`);

    let xToStr = x => `${ValueAdaptor.toDDMMYY(x)}`;

    const lines = [
      {
        color: Design.getColor(0, 2),
        y: yValues,
        yTotal: yValues[yValues.length-1]
      },
      {
        color: Design.getBaselineColor(),
        y: yBaseline,
        yTotal: yBaseline[yBaseline.length-1]
      }
    ];

    return (
      <GrowthLineChart
        key={name}
        name={name
          .replace(/_/g, " ")
          .split(" ")
          .map(s => s.charAt(0).toUpperCase() + s.slice(1))
          .join(" ")}
        x={xValues}
        yToStr={yToStr}
        xToStr={xToStr}
        lines={lines}
      />
    );
  };

  render() {
    let isLoaded = this.labels.map(l=>this.state.get().ressources[l]).reduce((acc,v) => acc && v.isLoaded)
    if(!isLoaded){
      return (<div className="page product care">
        <h1>Care</h1>
        <p className="desc">Total tickets for 10'000 active users over the past year. All Data are grouped by week.<br/> By default the total is made over the last available week.</p>
        <div className="loader_wrapper">
          <Loader/>
        </div>
      </div>);
    }

    let ressources = this.state.get().ressources;
    ressources = this.labels.map(l=>[l,ressources[l]])
    ressources.sort((a,b) => b[1].data[b[1].data.length-1].value - a[1].data[a[1].data.length-1].value)

    return (
      <div className="page product care">
        <h1>Care</h1>
        <p className="desc">Total tickets for 10'000 active users over the past year. All Data are grouped by week.<br/> By default the total is made over the last available week.</p>
        <div className="grid_layout">
          {ressources.map(l => this.buildLineChart(l[0], l[1]))}
        </div>
      </div>
    );
  }
}

export default Care;
