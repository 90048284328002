import React, { Component } from "react";
import LineChart from "./LineChart.js";
import State from "../../../utils/State.js";
import Design from "../../../utils/design.js";
import PropTypes from "prop-types";
import ValueAdaptor from "../../../utils/ValueAdaptor.js"

class SimpleLineChart extends Component {
  constructor(props) {
    super(props);

    if (this.props.x.length == 0) {
      throw new Error("Invalid Argument : SimpleChart requires data");
    }

    this.xToStr = x => {
      if (x instanceof Date) {
        return ValueAdaptor.toDDMMYYYY(x);
      }
      return x.toFixed(1);
    };

    this.xToStr = props.xToStr ? props.xToStr : this.xToStr;
    this.yToStr = props.yToStr ? props.yToStr : y => y.toFixed(1);

    this.defaultState = {
      x: this.props.x[this.props.x.length - 1],
      y: this.props.y[this.props.x.length - 1]
    };
    this.state = new State(this)
      .add("x", this.defaultState.x)
      .add("y", this.defaultState.y);
  }



  hover = selId => {
    if (selId === undefined) {
      this.state.set(s => {
        s.x = this.defaultState.x;
        s.y = this.defaultState.y;
      });
      return;
    }
    this.state.set(s => {
      s.x = this.props.x[selId];
      s.y = this.props.y[selId];
    });
    if (this.props.hover) {
      this.props.hover(selId);
    }
  };

  render() {
    return (
      <div className="simple_line_chart">
        <div className="info_wrapper">
          <div className="info_cell">
            <span className="label">{this.props.xName}</span>
            <span className="value">{this.xToStr(this.state.get().x)}</span>
          </div>
          <div className="info_cell">
            <span className="label">{this.props.yName}</span>
            <span className="value">{this.yToStr(this.state.get().y)}</span>
          </div>
        </div>
        <LineChart
          hasAxis={this.props.hasAxis}
          x={this.props.x}
          lines={[
            {
              color: Design.getColor(0, 1),
              y: this.props.y
            }
          ]}
          is_log_scale={this.props.is_log_scale}
          hover={this.hover}
        />
      </div>
    );
  }
}

SimpleLineChart.propTypes = {
  hasAxis: PropTypes.bool.isRequired,
  is_log_scale: PropTypes.bool,
  xName: PropTypes.string.isRequired,
  x: PropTypes.array.isRequired,
  yName: PropTypes.string.isRequired,
  y: PropTypes.array.isRequired,
  hover: PropTypes.func,
  xToStr: PropTypes.func,
  yToStr: PropTypes.func
};

export default SimpleLineChart;
