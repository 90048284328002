import React, { Component } from "react";
import Loader from "../../elements/utils/Loader.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Table from "../../elements/data/Table.js";
import State from "../../../utils/State.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";
import HorizontalBarChart from "../../elements/data/HorizontalBarChart.js";
import DateSelector from "../../elements/selectors/DateSelector.js";

class CardSides extends Component {
  render() {
    return (
      <div className="page card_sides">
        <CardFrontSide />
        <CardBackSide />
      </div>
    );
  }
}

export default CardSides;

class CardBackSide extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "printsPerFontName",
        path: "api/brandcontent/printouts_per_font_name"
      })
      .addRessource({
        name: "printsPerFontColor",
        path: "api/brandcontent/printouts_per_font_color"
      })
      .addRessource({
        name: "printsPerLayoutType",
        path: "api/brandcontent/printouts_per_layout_type"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getHorizontalBarChart(ressource) {
    if (!ressource.isLoaded) {
      return <Loader className="horizontal_bar_chart" />;
    }

    let labels = ressource.data.map(x =>
      x["name"].replaceAll(/postcard_back_/gi, "").replaceAll(/_/gi, " ")
    );
    let totalPrintouts = ressource.data
      .map(x => parseFloat(x["value"]))
      .reduce((a, b) => a + b, 0);
    let values = ressource.data.map(
      x => parseFloat(x["value"]) / totalPrintouts
    );

    return (
      <HorizontalBarChart
        labels={labels}
        values={values}
        xToStr={ValueAdaptor.toPercent}
      />
    );
  }

  render() {
    return (
      <div className="side back">
        <h1>Back Side</h1>
        <h2>Total Printouts Per Layout Display (Last 30 days)</h2>
        <p></p>
        {this.getHorizontalBarChart(
          this.state.get().ressources.printsPerLayoutType
        )}
        <h2>Total Printouts Per Font Name (Last 30 days)</h2>
        {this.getHorizontalBarChart(
          this.state.get().ressources.printsPerFontName
        )}
        <h2>Total Printouts Per Font Color (Last 30 days)</h2>
        {this.getHorizontalBarChart(
          this.state.get().ressources.printsPerFontColor
        )}
      </div>
    );
  }
}

class CardFrontSide extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "stickerRate",
        path: "api/brandcontent/sticker_rate",
        params: {
          from_date: ValueAdaptor.addDays(ValueAdaptor.utcToday(), -30),
          until_date: ValueAdaptor.utcToday()
        }
      })
      .addRessource({
        name: "stickerRateByWeek",
        path: "api/brandcontent/sticker_rate_weekly"
      })
      .addRessource({
        name: "stickerRateByName",
        path: "api/brandcontent/sticker_rate_by_name",
        params: {
          from_date: ValueAdaptor.addDays(ValueAdaptor.utcToday(), -30),
          until_date: ValueAdaptor.utcToday()
        }
      })
      .add("fromDate", ValueAdaptor.addDays(ValueAdaptor.utcToday(), -30))
      .add("untilDate", ValueAdaptor.utcToday());
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getStickerRateByName() {
    let ressource = this.state.get().ressources.stickerRateByName;

    if (!ressource.isLoaded) {
      return <Loader />;
    }

    let columns = [
      {
        name: "Category",
        mapper: x => x["category"],
        isHeader: true
      },
      {
        name: "Total",
        mapper: x => ValueAdaptor.toAutoUnit(x["has_category_rate"] * 10000),
        isHeader: true
      },
      {
        name: "Sticker",
        mapper: x => (
          <div
            style={{ "background-color": "#7c8dfc", "text-align": "center" }}
            key={x["url"]}
          >
            <img
              src={x["url"]}
              style={{ width: "50px", height: "50px" }}
              key={x["url"]}
            />
          </div>
        ),
        isHeader: true
      },
      {
        name: "Total",
        mapper: x => ValueAdaptor.toAutoUnit(x["has_sticker_rate"] * 10000)
      }
    ];

    return <Table data={ressource.data} cols={columns} />;
  }

  getStickerRateByWeek() {
    let ressource = this.state.get().ressources.stickerRateByWeek;

    if (!ressource.isLoaded) {
      return <Loader className="simple_line_chart" />;
    }

    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(e => e.value);

    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Has Sticker %"
        y={y}
        is_log_scale={false}
        yToStr={x => ValueAdaptor.toPercent(x)}
      />
    );
  }

  getStickerRate() {
    let ressource = this.state.get().ressources.stickerRate;

    if (!ressource.isLoaded) {
      return <span className="sticker_rate"> - %</span>;
    }

    return (
      <div className="sticker_popularity_desc">
        <span className="sticker_rate">
          {ValueAdaptor.toPercent(ressource.data["total_cards"])}
        </span>
        <span className="sticker_text">
          of <br />
          postcards
          <br /> contain
          <br /> at least
          <br /> one sticker.
        </span>
        <span className="sticker_rate">
          {ValueAdaptor.toPercent(ressource.data["total_users"])}
        </span>
        <span className="sticker_text">
          of users
          <br /> who have
          <br /> posted a<br /> card tried stickers.
        </span>
      </div>
    );
  }

  render() {
    return (
      <div className="side front">
        <h1>Front Side</h1>

        <h2>Card Having At Least One Sticker</h2>
        {this.getStickerRateByWeek()}

        <h2>Sticker : Interactive Breakthrough</h2>
        <div className="date_selection">
          From
          <DateSelector
            defaultDate={this.state.get().fromDate}
            callback={x => {
              this.state.set(s => {
                s.ressources.stickerRateByName.params["from_date"] = x;
                s.ressources.stickerRate.params["from_date"] = x;
              });
            }}
          />
          until
          <DateSelector
            defaultDate={this.state.get().untilDate}
            callback={x => {
              this.state.set(s => {
                s.ressources.stickerRateByName.params["until_date"] = x;
                s.ressources.stickerRate.params["until_date"] = x;
              });
            }}
          />
        </div>
        {this.getStickerRate()}
        <p>
          Each value represents the total number of cards with at least one of
          the given category/sticker every 10'000 cards.
        </p>
        {this.getStickerRateByName()}
      </div>
    );
  }
}
