import React, { Component } from "react";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";

class General extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "cumulatedRevenue",
        path: "api/general/cumulated_revenue"
      })
      .addRessource({
        name: "cumulatedClients",
        path: "api/general/cumulated_clients"
      })
      .addRessource({
        name: "cumulatedUsers",
        path: "api/general/cumulated_users"
      })
      .addRessource({
        name: "cumulatedCardPrintouts",
        path: "api/general/cumulated_card_printouts"
      })
      .addRessource({
        name: "cumulatedPhotobookPrintouts",
        path: "api/general/cumulated_photobook_printouts"
      })
      .addRessource({
        name: "cumulatedContacts",
        path: "api/product/contact_counter"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getChart(ressource) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(e => e.total);
    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Total"
        y={y}
        is_log_scale={false}
        yToStr={x => ValueAdaptor.toAutoUnit(x)}
      />
    );
  }

  render() {
    return (
      <div className="page business general">
        <h1>General</h1>

        <div className="row">
          <div className="rowItem">
            <h2>Users</h2>
            <p>
              Total number of unique users who have registered an account since
              the creation of Fizzer.
            </p>
            {this.getChart(this.state.get().ressources.cumulatedUsers)}
          </div>
          <div className="rowItem">
            <h2>Clients</h2>
            <p>
              Total number of unique users who have made at least one payment
              since the creation of Fizzer.
            </p>
            {this.getChart(this.state.get().ressources.cumulatedClients)}
          </div>
        </div>

        <div className="row">
          <div className="rowItem">
            <h2>Revenue</h2>
            <p>
              Total revenues since the creation of Fizzer, including all VAT
              taxes. B2B revenue is not included to these results.
            </p>
            {this.getChart(this.state.get().ressources.cumulatedRevenue)}
          </div>
          <div className="rowItem">
            <h2>Contacts</h2>
            <p>
              Total number of contacts added by our customers since the creation
              of Fizzer.
            </p>
            {this.getChart(this.state.get().ressources.cumulatedContacts)}
          </div>
        </div>

        <div className="row">
          <div className="rowItem">
            <h2>Cards</h2>
            <p>
              All the cards (greetings + postcards) sent since the creation of
              Fizzer. All cards included in a set are counted separately.
            </p>
            {this.getChart(this.state.get().ressources.cumulatedCardPrintouts)}
          </div>
          <div className="rowItem">
            <h2>Albums</h2>
            <p>Total number of albums sent since the creation of Fizzer.</p>
            {this.getChart(
              this.state.get().ressources.cumulatedPhotobookPrintouts
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default General;
