import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class SubscriptionRevenue extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "subscriptionRevenue",
      path: "api/finance/subscription_revenue"
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getColumns() {
    let cols = [
      {
        name: "year",
        mapper: x => ValueAdaptor.toYYYY(x.date),
        isHeader: true
      },
      {
        name: "month",
        mapper: x => ValueAdaptor.toMonthName(x.date),
        isHeader: true
      },
      {
        name: "club [quarterly]",
        mapper: x => parseFloat(x["club_quarterly_revenue"]).toFixed(2)
      },
      {
        name: "club [yearly]",
        mapper: x => parseFloat(x["club_yearly_revenue"]).toFixed(2)
      },
      {
        name: "family [monthly]",
        mapper: x => parseFloat(x["family_space_monthly_revenue"]).toFixed(2)
      },
      {
        name: "family [yearly]",
        mapper: x => parseFloat(x["family_space_yearly_revenue"]).toFixed(2)
      },
      {
        name: "Total",
        mapper: x =>
          ValueAdaptor.toAutoUnit(
            parseFloat(x["family_space_yearly_revenue"]) +
              parseFloat(x["family_space_monthly_revenue"]) +
              parseFloat(x["club_yearly_revenue"]) +
              parseFloat(x["club_quarterly_revenue"]),
            "€"
          )
      }
    ];
    return cols;
  }

  render() {
    let table = <Loader />;
    if (this.state.get().ressources.subscriptionRevenue.isLoaded) {
      table = (
        <Table
          data={this.state.get().ressources.subscriptionRevenue.data}
          cols={this.getColumns()}
        />
      );
    }

    return (
      <div className="page finance subscription_revenue">
        <h1>Subscription Revenue</h1>
        {table}
      </div>
    );
  }
}

export default SubscriptionRevenue;
