import React, { Component } from "react";
import Table from "../../elements/data/Table.js";
import ValueAdaptator from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class PaidMedia extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "paidMedia",
      path: "api/acquisition/paid_media"
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  activationOverallColumns = () => [
    {
      name: "Year",
      mapper: x => ValueAdaptator.toYYYY(x.from_date),
      isHeader: true
    },
    {
      name: "",
      mapper: x => (x.from_date == x.to_date ? "hot" : ""),
      isHeader: true
    },
    {
      name: "From",
      mapper: x => ValueAdaptator.toDDMM(x.from_date),
      isHeader: true
    },
    {
      name: "To",
      mapper: x => ValueAdaptator.toDDMM(x.to_date),
      isHeader: true
    },
    {
      name: "Regs",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_registrations)
    },
    {
      name: "Actis",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_activations)
    },
    {
      name: "%",
      mapper: x =>
        ValueAdaptator.toPercent(
          x.total_activations / Math.max(x.total_registrations, 1)
        )
    }
  ];

  activationAppColummns = () => [
    {
      name: "Year",
      mapper: x => ValueAdaptator.toYYYY(x.from_date),
      isHeader: true
    },
    {
      name: "",
      mapper: x => (x.from_date == x.to_date ? "hot" : ""),
      isHeader: true
    },
    {
      name: "From",
      mapper: x => ValueAdaptator.toDDMM(x.from_date),
      isHeader: true
    },
    {
      name: "To",
      mapper: x => ValueAdaptator.toDDMM(x.to_date),
      isHeader: true
    },
    {
      name: "Regs",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_app_registrations)
    },
    {
      name: "Actis",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_app_activations)
    },
    {
      name: "%",
      mapper: x =>
        ValueAdaptator.toPercent(
          x.total_app_activations / Math.max(x.total_app_registrations, 1)
        )
    }
  ];

  activationPaidColumns = () => [
    {
      name: "Year",
      mapper: x => ValueAdaptator.toYYYY(x.from_date),
      isHeader: true
    },
    {
      name: "",
      mapper: x => (x.from_date == x.to_date ? "hot" : ""),
      isHeader: true
    },
    {
      name: "From",
      mapper: x => ValueAdaptator.toDDMM(x.from_date),
      isHeader: true
    },
    {
      name: "To",
      mapper: x => ValueAdaptator.toDDMM(x.to_date),
      isHeader: true
    },
    {
      name: "Regs",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_paid_registrations)
    },
    {
      name: "Actis",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_paid_activations)
    },
    {
      name: "%",
      mapper: x =>
        ValueAdaptator.toPercent(
          x.total_paid_activations / Math.max(x.total_paid_registrations, 1)
        )
    }
  ];

  activationWebColumns = () => [
    {
      name: "Year",
      mapper: x => ValueAdaptator.toYYYY(x.from_date),
      isHeader: true
    },
    {
      name: "",
      mapper: x => (x.from_date == x.to_date ? "hot" : ""),
      isHeader: true
    },
    {
      name: "From",
      mapper: x => ValueAdaptator.toDDMM(x.from_date),
      isHeader: true
    },
    {
      name: "To",
      mapper: x => ValueAdaptator.toDDMM(x.to_date),
      isHeader: true
    },
    {
      name: "Regs",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_web_registrations)
    },
    {
      name: "Actis",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_web_activations)
    },
    {
      name: "%",
      mapper: x =>
        ValueAdaptator.toPercent(
          x.total_web_activations / Math.max(x.total_web_registrations, 1)
        )
    }
  ];

  revenueAndCostsColumns = () => [
    {
      name: "Year",
      mapper: x => ValueAdaptator.toYYYY(x.from_date),
      isHeader: true
    },
    {
      name: "",
      mapper: x => (x.from_date == x.to_date ? "hot" : ""),
      isHeader: true
    },
    {
      name: "From",
      mapper: x => ValueAdaptator.toDDMM(x.from_date),
      isHeader: true
    },
    {
      name: "To",
      mapper: x => ValueAdaptator.toDDMM(x.to_date),
      isHeader: true
    },
    {
      name: "New € (App)",
      mapper: x => ValueAdaptator.toAutoUnit(x.new_revenue_app, "€")
    },
    {
      name: "New € (Web)",
      mapper: x => ValueAdaptator.toAutoUnit(x.new_revenue_web, "€")
    },
    {
      name: "Costs",
      mapper: x => ValueAdaptator.toAutoUnit(x.total_cost, "$")
    }
  ];

  render() {
    if (!this.state.get().ressources.paidMedia.isLoaded) {
      return (
        <div className="page acquisition paid_media">
          <Loader />
        </div>
      );
    }

    return (
      <div className="page acquisition paid_media">
        <div className="chart_wrapper">
          <h2>Paid Media</h2>
          <Table
            data={this.state.get().ressources.paidMedia.data}
            cols={this.activationPaidColumns()}
          />
        </div>
        <div className="chart_wrapper">
          <h2>Overall</h2>
          <Table
            data={this.state.get().ressources.paidMedia.data}
            cols={this.activationOverallColumns()}
          />
        </div>
        <div className="chart_wrapper">
          <h2>Android/IOS</h2>
          <Table
            data={this.state.get().ressources.paidMedia.data}
            cols={this.activationAppColummns()}
          />
        </div>
        <div className="chart_wrapper">
          <h2>Web</h2>
          <Table
            data={this.state.get().ressources.paidMedia.data}
            cols={this.activationWebColumns()}
          />
        </div>
        <div className="chart_wrapper revenue">
          <h2>New Revenue & Costs </h2>
          <Table
            data={this.state.get().ressources.paidMedia.data}
            cols={this.revenueAndCostsColumns()}
          />
        </div>
      </div>
    );
  }
}

export default PaidMedia;
