import React, { Component } from "react";
import PropTypes from "prop-types";

class InputText extends Component {
  keyDown = e => {
    let inp = String.fromCharCode(e.keyCode);
    if (
      this.props.regex &&
      !this.props.regex.test(e.target.value + inp) &&
      /[a-zA-Z0-9-_ ]/.test(inp)
    ) {
      event.preventDefault();
    }
  };

  keyUp = e => {
    if (this.props.callback) {
      if (
        this.props.regex === undefined ||
        this.props.regex.test(e.target.value)
      ) {
        this.props.callback(e.target.value || this.props.defaultText);
      }
      else {
        this.props.callback(this.props.defaultText);
      }
    }
  };

  render() {
    return (
      <input
        className="input_text"
        type="text"
        onKeyUp={this.keyUp}
        onKeyDown={this.keyDown}
        placeholder={this.props.defaultText}
      ></input>
    );
  }
}

InputText.propTypes = {
  defaultText: PropTypes.string,
  regex: PropTypes.object,
  callback: PropTypes.func
};

export default InputText;
