import * as d3 from "d3";

class DesignClass {
  constructor() {
    this.baseColors = [
      "#7c8dfc",
      "#ffa47f",
      "#3bdace",
      "#ff7078",
      "#cd89ee",
      "#a8d65f"
    ];
    this.continousColors = d3
      .scaleOrdinal()
      .domain([0, 1.0])
      .range(d3.schemeSet3);
  }

  getColor(i, paletteSize) {
    return paletteSize <= this.baseColors.length
      ? this.baseColors[i]
      : this.continousColors(i / paletteSize);
  }

  getBaselineColor() {
    return "rgb(195,195,195)";
  }

  getScoreGradient(min, avg, max) {
    let minColor = [255, 170, 50];
    let avgColor = [40, 40, 40];
    let maxColor = [88, 183, 154];

    let func = value => {
      if (value < avg) {
        let avgRatio =
          Math.abs(min - Math.max(value, min)) / Math.abs(min - avg);
        let r = Math.floor(
          avgRatio * avgColor[0] + (1 - avgRatio) * minColor[0]
        );
        let g = Math.floor(
          avgRatio * avgColor[1] + (1 - avgRatio) * minColor[1]
        );
        let b = Math.floor(
          avgRatio * avgColor[2] + (1 - avgRatio) * minColor[2]
        );
        return `rgb(${r},${g},${b})`;
      } else {
        let avgRatio =
          Math.abs(max - Math.min(value, max)) / Math.abs(max - avg);

        let r = Math.floor(
          avgRatio * avgColor[0] + (1 - avgRatio) * maxColor[0]
        );
        let g = Math.floor(
          avgRatio * avgColor[1] + (1 - avgRatio) * maxColor[1]
        );
        let b = Math.floor(
          avgRatio * avgColor[2] + (1 - avgRatio) * maxColor[2]
        );
        return `rgb(${r},${g},${b})`;
      }
    };
    return func;
  }
}

let Design = new DesignClass();

export default Design;
