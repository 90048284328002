import React, { Component } from "react";
import PropTypes from "prop-types";
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { parseDate } from 'react-day-picker/moment';

class DateSelector extends Component {

  formatDate = (date) => {
    return `${
      ("" + date.getUTCDate()).padStart(2, "0")
        }/${(
      "" + (date.getUTCMonth() + 1)).padStart(2, "0")
        }/${date.getUTCFullYear()}`;
  }

  render(){
    return <DayPickerInput
    value={this.props.defaultDate ||  new Date("2014-01-01")}
    placeholder={this.props.placeholder}
    format={'DD/MM/yyyy'}
    formatDate={this.formatDate}
    parseDate={parseDate}
    dayPickerProps={{
      numberOfMonths: 1
    }}
    onDayChange={(date) => {
      if(date !== undefined){
        (this.props.callback || ((x) => {}))(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())))
      }
    }}
    />
  }

}

DateSelector.propTypes = {
  defaultDate: PropTypes.object,
  callback: PropTypes.func,
  placeholder: PropTypes.string
};

export default DateSelector;
