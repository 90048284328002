import React, { Component } from "react";
import Loader from "../../elements/utils/Loader.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Table from "../../elements/data/Table.js";
import State from "../../../utils/State.js";
import ItemSelector from "../../elements/selectors/ItemSelector.js";
import Design from "../../../utils/design.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";
import HorizontalBarChart from "../../elements/data/HorizontalBarChart.js";

class OverviewPrintouts extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "events",
        path: "api/brandcontent/card_printouts_resume",
        params: {
          group_by: "event",
          language: "fr",
          format: "postcard"
        }
      })
      .addRessource({
        name: "categories",
        path: "api/brandcontent/card_printouts_resume",
        params: {
          group_by: "category",
          language: "fr",
          format: "postcard"
        }
      })
      .addRessource({
        name: "printoutsPerLanguage",
        path: "api/brandcontent/printouts_per_language"
      })
      .addRessource({
        name: "printoutsPerProduct",
        path: "api/brandcontent/printouts_per_product"
      })
      .addRessource({
        name: "postcardPrintoutsPerCategory",
        path: "api/brandcontent/card_categories_per_week",
        params: {
          language: "fr",
          format: "postcard"
        }
      })
      .addRessource({
        name: "greetingPrintoutsPerCategory",
        path: "api/brandcontent/card_categories_per_week",
        params: {
          language: "fr",
          format: "greeting_card"
        }
      })
      .add("selId", undefined);
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getCardTable(name) {
    let data = this.state.get().ressources[name].data;
    if (data.length == 0) {
      return (
        <div className="data_table no_data">
          <span className="no_data">NO DATA</span>
        </div>
      );
    }
    let columns = [{ name: "name", mapper: x => x.name, isHeader: true }];
    for (let colName of ["yesterday", "last_30_days", "last_365_days"]) {
      let total = data
        .map(x => new Number(x[colName]))
        .reduce((acc, v) => acc + v);
      columns.push({
        name: colName.replace(/_/g, " "),
        mapper: x => ValueAdaptor.toAutoUnit(x[colName])
      });
      columns.push({
        name: "%",
        mapper: x => ValueAdaptor.toPercent(x[colName] / total)
      });
    }
    return <Table data={data} cols={columns} />;
  }

  getCardControler() {
    return (
      <div className="controler">
        <ItemSelector
          items={{
            Postcards: "postcard",
            "Postcards Magnet": "postcard_magnet",
            "Square Cards Magnet": "square_card_magnet",
            Greetings: "greeting_card",
            "Postcard Video": "postcard_video",
            "Postcards [SET]": "set_of_postcards",
            "Greetings [SET]": "set_of_greeting_cards"
          }}
          defaultLabel="Postcards"
          callback={x => {
            this.state.set(s => {
              s.ressources["categories"].params.format = x;
              s.ressources["families"].params.format = x;
            });
          }}
        />
        in
        <ItemSelector
          items={{
            FR: "fr",
            ES: "es",
            EN: "en",
            PT: "pt",
            IT: "it",
            DE: "de"
          }}
          defaultLabel="FR"
          callback={x => {
            this.state.set(s => {
              s.ressources["events"].params.language = x;
              s.ressources["categories"].params.language = x;
            });
          }}
        />
      </div>
    );
  }

  hover = selId => {
    this.state.set(s => {
      s.selId = selId;
    });
  };

  getPrintoutsComparisons(labels, ressource, name) {
    if (!ressource.isLoaded) {
      return (
        <div className="multi_lines_chart">
          <Loader />
        </div>
      );
    }
    let lines = [];
    for (let i = 0; i < labels.length; i++) {
      lines.push({
        name: labels[i].name,
        color: Design.getColor(i, labels.length),
        y: ressource.data.map(x => new Number(x[labels[i].col]))
      });
    }
    let x = ressource.data.map(x => ValueAdaptor.parseUTCDate(x["date"]));
    return (
      <MultiLinesChart
        key={`${name}_${this.state.get().selId}`}
        x={x}
        xToStr={ValueAdaptor.toDDMMYYYY}
        yToStr={ValueAdaptor.toAutoUnit}
        lines={lines}
        hasAxis={true}
        hover={this.hover}
        is_log_scale={true}
        selectedId={this.state.get().selId}
      />
    );
  }

  getPrintoutCategory(ressource, xToStr = x => x) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let maxBins = 10;

    let displayedRow = ressource.data.rows.slice(-1)[0];

    if (this.state.get().selId != null) {
      let sel_date = this.state.get().ressources.printoutsPerProduct.data[
        this.state.get().selId
      ]["date"];
      let selRow = ressource.data.rows.filter(x => x["date"] == sel_date);
      if (selRow.length == 1) {
        displayedRow = selRow[0];
      }
    }

    return (
      <HorizontalBarChart
        labels={ressource.data.columns.slice(0, maxBins)}
        values={displayedRow["values"].slice(0, maxBins)}
        xToStr={xToStr}
      />
    );
  }

  render() {
    return (
      <div className="page brandcontent overview_printouts">
        <h1>Printout Overview</h1>
        <p>Every numbers represent a daily volume of printouts.</p>
        <div className="row">
          <div className="row_item">
            <div className="chart_title">
              <span>Printouts Per Product</span>
            </div>
            {this.getPrintoutsComparisons(
              [
                { col: "postcard", name: "Simple Card" },
                { col: "magnetic_card", name: "Magnetic Card" },
                { col: "greeting_card", name: "Greeting" },
                { col: "square_photobook", name: "Squared Album" },
                { col: "set_of_postcards", name: "Postcard [SET]" },
                { col: "set_of_greeting_cards", name: "Greeting [SET]" },
                { col: "gazette", name: "Gazette" }
              ],
              this.state.get().ressources.printoutsPerProduct,
              "postcards_per_product"
            )}
          </div>
          <div className="row_item">
            <div className="chart_title">
              <span>Printouts Per Language</span>
            </div>
            {this.getPrintoutsComparisons(
              [
                { col: "fr", name: "FR" },
                { col: "en", name: "EN" },
                { col: "de", name: "DE" },
                { col: "it", name: "IT" },
                { col: "es", name: "ES" },
                { col: "pt", name: "PT" }
              ],
              this.state.get().ressources.printoutsPerLanguage,
              "postcards_per_language"
            )}
          </div>
        </div>
        <div className="row">
          <div className="row_item">
            <h2>Printouts Per Category [FR/Postcards]</h2>
            {this.getPrintoutCategory(
              this.state.get().ressources.postcardPrintoutsPerCategory,
              x => x.toFixed(1)
            )}
          </div>
          <div className="row_item">
            <h2>Printouts Per Category [FR/Greetings]</h2>
            {this.getPrintoutCategory(
              this.state.get().ressources.greetingPrintoutsPerCategory,
              x => x.toFixed(1)
            )}
          </div>
        </div>
        <div className="row thin">{this.getCardControler()}</div>
        <div className="row">
          <div className="table_wrapper">
            <div className="row_item">
              <h2>Printouts Per Category</h2>
              {this.state.get().ressources["events"].isLoaded ? (
                this.getCardTable("events")
              ) : (
                <Loader />
              )}
            </div>
            <div className="row_item">
              <h2> Printouts Per Category</h2>
              {this.state.get().ressources["categories"].isLoaded ? (
                this.getCardTable("categories")
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverviewPrintouts;
