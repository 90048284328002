import ValueAdaptor from "./ValueAdaptor.js";

class ApiAccess {
  static paramAdaptor = param_value =>
    param_value instanceof Date
      ? ValueAdaptor.toYYYYMMDD(param_value)
      : param_value;

  static getUrl(path, params) {
    params = Object.keys(params)
      .filter(k => params[k] != undefined)
      .map(k => `${k}=${ApiAccess.paramAdaptor(params[k])}`)
      .join("&");
    return `${window.location.origin}/${path}${
      params.length > 0 ? "?" : ""
    }${params}`;
  }

  static fetch(path, urlParams, requestParams = {}) {
    return fetch(ApiAccess.getUrl(path, urlParams), requestParams).then(res => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Something went wrong");
      }
    });
  }

  static getCsrfToken() {
    return document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
  }
}

export default ApiAccess;
