import React, { Component } from "react";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";
import SimpleLineChart from "../../elements/data/SimpleLineChart.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";
import Design from "../../../utils/design.js";

class PaymentFlow extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "paymentFlow",
        path: "api/product/payment_flow_details"
      })
      .addRessource({
        name: "paymentProvider",
        path: "api/product/paypal_vs_stripe_usage"
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getPaymentFlowRatio() {
    let ressource = this.state.get().ressources.paymentFlow;
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(
      e => e.total_in_process / (e.total_in_process + e.total_basic)
    );
    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Payment In Process %"
        y={y}
        is_log_scale={false}
        yToStr={ValueAdaptor.toPercent}
      />
    );
  }

  getPaymentProvider(type) {
    let ressource = this.state.get().ressources.paymentProvider;
    if (!ressource.isLoaded) {
      return <Loader />;
    }
    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let lines = [];

    if (type == "only-new-business") {
      lines.push({
        name: "Paypal",
        color: Design.getColor(0, 2),
        y: ressource.data.map(e => e.new_paypal)
      });
      lines.push({
        name: "Stripe",
        color: Design.getColor(1, 2),
        y: ressource.data.map(e => e.new_stripe)
      });
    } else {
      lines.push({
        name: "Paypal",
        color: Design.getColor(0, 2),
        y: ressource.data.map(e => e.new_paypal + e.repeat_paypal)
      });
      lines.push({
        name: "Stripe",
        color: Design.getColor(1, 2),
        y: ressource.data.map(e => e.new_stripe + e.repeat_stripe)
      });
    }
    return (
      <MultiLinesChart
        x={x}
        yToStr={y => ValueAdaptor.toAutoUnit(y, "€")}
        xToStr={ValueAdaptor.toDDMMYYYY}
        lines={lines}
        hasAxis={true}
        is_log_scale={false}
      />
    );
  }

  getAverageCartPerFlow() {
    let ressource = this.state.get().ressources.paymentFlow;
    if (!ressource.isLoaded) {
      return (
        <div className="multi_lines_chart">
          <Loader />
        </div>
      );
    }
    let lines = [];
    lines.push({
      name: "In Flow",
      color: Design.getColor(0, 2),
      y: ressource.data.map(e => e.avg_cart_in_process_no_vat)
    });
    lines.push({
      name: "Basic",
      color: Design.getColor(1, 2),
      y: ressource.data.map(e => e.avg_cart_basic_no_vat)
    });
    let x = ressource.data.map(x => ValueAdaptor.parseUTCDate(x["date"]));
    return (
      <MultiLinesChart
        x={x}
        yToStr={y => ValueAdaptor.toAutoUnit(y, "€")}
        xToStr={ValueAdaptor.toDDMMYYYY}
        lines={lines}
        hasAxis={true}
        is_log_scale={true}
      />
    );
  }

  render() {
    return (
      <div className="page product payment_flow">
        <h1>Payment Flow </h1>
        <p className="general_description">
          A payment can be made either on the purchase page (basic) or during
          the card creation process (inflow). <br />
          We consider any payment followed by the creation of a card for less
          than 90 seconds as a payment belonging to the "inflow" type.
        </p>
        <div className="row">
          <div className="rowItem">
            <h2>In-Flow Payments %</h2>
            <p>Ratio of payment that belong to the type "inflow" over time.</p>
            {this.getPaymentFlowRatio()}
          </div>
          <div className="rowItem">
            <h2>Average Cart</h2>
            <p>Average cart without V.A.T for the 2 proposed types.</p>
            {this.getAverageCartPerFlow()}
          </div>
        </div>
        <div className="row">
          <div className="rowItem">
            <h2>Paypal vs Stripe Payments</h2>
            <p>
              Percentage of payment going through paypal during the last 30 days
            </p>
            {this.getPaymentProvider("all")}
          </div>
          <div className="rowItem">
            <h2>Paypal vs Stripe Payments [New Business Only]</h2>
            <p>
              Percentage of payment going through paypal during the last 30 days
            </p>
            {this.getPaymentProvider("only-new-business")}
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentFlow;
