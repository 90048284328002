import React, { Component } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

/* 
  LineChart assumes sorted rows
*/
class LineChart extends Component {
  constructor(props) {
    super(props);

    if (this.props.x.length < 1 || this.props.lines.length < 1) {
      throw new Error("Invalid Argument : nothing to draw");
    }

    this.isDateUnit = this.props.x[0] instanceof Date;
  }

  componentDidMount() {
    this.createChart(
      this.node,
      this.props.x,
      this.props.lines,
      this.props.hasAxis,
      this.props.hover,
      this.props.interestFromX,
      this.props.interestUntilX
    );
  }

  getDrawParameters(node, x, lines, hasAxis){
    const margin = {
      top: 5,
      right: hasAxis ? 10 : 0,
      bottom: hasAxis ? 30 : 1,
      left: hasAxis ? 10 : 0
    };

    const dim = {
      width: node.offsetWidth - margin.left - margin.right,
      height: node.offsetHeight - margin.top - margin.bottom
    };

    const minX = x.reduce((acc, v) => (v < acc ? v : acc));
    const maxX = x.reduce((acc, v) => (v > acc ? v : acc));

    const minY = lines
      .flatMap(line => line.y)
      .reduce((acc, v) => Math.min(acc, v));

    const maxY = lines
      .flatMap(line => line.y)
      .reduce((acc, v) => Math.max(acc, v));

    const xScale = d3
      .scaleLinear()
      .domain([minX, maxX])
      .range([0, dim.width]);

    let yScale = d3
      .scaleLinear()
      .domain([Math.min(0, minY), maxY])
      .range([dim.height, 0]);

    if (this.props.is_log_scale) {
      yScale = d3
        .scaleLog()
        .domain([1, maxY])
        .range([dim.height, 0])
        .base(10);
    }

    const mappers = lines.map(line => {
      const lineMapper = d3
        .line()
        .x(d => xScale(d.x))
        .y(d => yScale(d.y + (this.props.is_log_scale ? 1 : 0)));

      const areaMapper = d3
        .area()
        .x(d => xScale(d.x))
        .y0(dim.height)
        .y1(d => yScale(d.y + (this.props.is_log_scale ? 1 : 0)));

      return { line: lineMapper, area: areaMapper };
    });

    return {
      margin,
      dim,
      scaler: { x: xScale, y: yScale },
      mappers,
      minX: minX,
      maxX: maxX,
      maxY: maxY
    };
  }

  getSvg(node, lines, params) {
    let svg = d3
      .select(node)
      .append("svg")
      .attr(
        "width",
        params.dim.width + params.margin.left + params.margin.right
      )
      .attr(
        "height",
        params.dim.height + params.margin.top + params.margin.bottom
      )
      .attr("class", lines.length <= 1 ? "single_line" : "multi_lines");

    let backgroundGroup = svg
      .append("g")
      .attr("transform", `translate(${params.margin.left},0)`)
      .attr("width", params.dim.width)
      .attr(
        "height",
        params.dim.height + params.margin.bottom + params.margin.top
      );

    let chartGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${params.margin.left},${params.margin.top})`
      )
      .attr("width", params.dim.width)
      .attr("height", params.dim.height);

    let events = svg
      .append("g")
      .attr(
        "transform",
        `translate(${params.margin.left},${params.margin.top})`
      )
      .attr("width", params.dim.width)
      .attr("height", params.dim.height);

    return {
      svg: svg,
      chart: chartGroup,
      background: backgroundGroup,
      events: events
    };
  }

  createChart(node, x, lines, hasAxis, hover, interestFromX, interestUntilX) {
    const params = this.getDrawParameters(node, x, lines, hasAxis);

    const nodes = this.getSvg(node, lines, params);

    this.drawAxis(hasAxis, nodes, params);

    this.drawLines(nodes, x, lines, params, interestFromX, interestUntilX);

    this.addCallback(nodes, x, lines, hover, params);
  }

  drawAxis(hasAxis, nodes, params) {
    if (!hasAxis) {
      return;
    }
    let axisBuilder = d3.axisBottom(params.scaler.x).tickSize(2);

    if (this.isDateUnit) {
      axisBuilder = d3
        .axisBottom(params.scaler.x)
        .ticks(4)
        .tickFormat(d3.utcFormat("%d/%m/%Y"));
    }

    nodes.chart
      .append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0,${params.dim.height})`)
      .call(axisBuilder);
  }

  drawLines(nodes, x, lines, params, interestFromX, interestUntilX) {
    let selX = x.filter(value=> (interestFromX == undefined || interestFromX <= value) && (interestUntilX == undefined || value < interestUntilX))
    let fromX = selX[0]
    let toX = selX[selX.length - 1]
    fromX = params.scaler.x(fromX)
    toX = params.scaler.x(toX)

    nodes.background
      .append("g")
      .attr("class", "interest_area")
      .append("rect")
      .attr("x", fromX)
      .attr("y", 0)
      .attr("width", toX - fromX)
      .attr(
        "height",
        params.dim.height + params.margin.top + params.margin.bottom - 1
      );

    for (let i = lines.length - 1; i >= 0; i--) {
      let data = [...Array(this.props.lines[i].y.length).keys()].map(k => ({
        x: x[k],
        y: lines[i].y[k]
      })).filter(x=>x.y !=null)
      nodes.chart
        .append("path")
        .datum(data)
        .attr("class", `area area_${i}`)
        .attr("fill", lines[i].color)
        .attr("d", params.mappers[i].area);
      nodes.chart
        .append("path")
        .datum(data)
        .attr("class", `line line_${i}`)
        .attr("stroke", lines[i].color)
        .attr("d", params.mappers[i].line);
    }

    nodes
      .events
      .append("g")
      .attr("class", "mouse_move")
      .append("path")
      .attr("class", "abscisse_landmark")
      .style("opacity", "0");

    lines.map(l =>
      nodes.chart
        .append("g")
        .append("circle")
        .style("fill", "none")
        .attr("stroke", l.color)
        .attr("class", "line_landmark")
        .attr("r", 2)
        .style("opacity", 0)
    );

    if (this.props.selectedId) {
      this.drawPointSelection(nodes, x, lines, params, this.props.selectedId)
    }

  }

  drawPointSelection(nodes, x, lines, params, selId) {

    let line = nodes.events.select('.abscisse_landmark');
    let lineLandmarks = nodes.chart.selectAll('.line_landmark')
    let isLogScale = this.props.is_log_scale

    if (selId == undefined) {
      line.style("opacity", "0");
      lineLandmarks.style("opacity", "0")
      return;
    }

    lineLandmarks
      .style("opacity", function (d, i) {
        return lines[i].y[selId] != undefined ? "1" : "0"
      })
      .attr("cx", params.scaler.x(x[selId]))
      .attr("cy", function (d, i) {
        return lines[i].y[selId] != undefined ? params.scaler.y(lines[i].y[selId] + (isLogScale ? 1 : 0)) : 0
      })

    line
      .style("opacity", "1")
      .attr("d", function () {
        let d = `M${params.scaler.x(x[selId])},${params.dim.height}`;
        d += ` ${params.scaler.x(x[selId])},${0}`;
        return d;
      });

  }

  addCallback(nodes, x, lines, hover, params) {
    if (!hover) {
      return;
    }

    const bisect = d3.bisector(x => x).left;

    nodes.events
      .select(".mouse_move")
      .append("svg:rect")
      .attr("width", params.dim.width)
      .attr("height", params.dim.height)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseout", function () {
        this.drawPointSelection(nodes, x, lines, params, this.props.selectedId)
        hover();
      }.bind(this))
      .on("mousemove", function () {
        let mouseX = d3.mouse(nodes.events.select(".mouse_move").node())[0];
        let xRequested = params.scaler.x.invert(mouseX);
        let selId = bisect(x, xRequested, 1);
        selId =
          Math.abs(xRequested - x[selId - 1]) < Math.abs(xRequested - x[selId])
            ? selId - 1
            : selId;
        selId = Math.min(selId, x.length - 1);
        this.drawPointSelection(nodes, x, lines, params, selId)
        hover(selId);
      }.bind(this));
  }

  render() {
    return (
      <div
        className={`chart_wrapper${
          this.props.className ? ` ${this.props.className}` : ""
          }`}
        ref={node => (this.node = node)}
      ></div>
    );
  }
}

LineChart.propTypes = {
  className: PropTypes.string,
  hasAxis: PropTypes.bool.isRequired,
  is_log_scale: PropTypes.bool,
  x: PropTypes.array.isRequired,
  hover: PropTypes.func,
  interestFromX: PropTypes.any,
  interestUntilX: PropTypes.any,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      y: PropTypes.array.isRequired
    })
  ),
  selectedId: PropTypes.number
};

export default LineChart;

