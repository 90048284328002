import React, { Component } from "react";
import PropTypes from "prop-types";
import LineChart from "./LineChart.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";

class GrowthLineChart extends Component {
  constructor(props) {
    super(props);

    if (this.props.lines.length !== 2 || this.props.x.length == 0) {
      throw new Error(
        "Invalid Argument : GrowthLineChart requires 2 lines plus data"
      );
    }

    this.xToStr = x => {
      if (x instanceof Date) {
        return ValueAdaptor.toDDMMYYYY(x);
      }
      return x.toFixed(1);
    };

    this.xToStr = props.xToStr ? props.xToStr : this.xToStr;
    this.yToStr = props.yToStr ? props.yToStr : y => y.toFixed(1);
    this.minX = props.x.reduce((acc, v) => (acc < v ? acc : v));
    this.maxX = props.x.reduce((acc, v) => (acc > v ? acc : v));

    this.state = new State(this, {
      x: null,
      prevY: this.props.lines[1].yTotal,
      currY: this.props.lines[0].yTotal
    });
  }

  hover = selId => {
    if (selId === undefined) {
      this.state.set(s => {
        s.x = null;
        s.currY = this.props.lines[0].yTotal;
        s.prevY = this.props.lines[1].yTotal;
      });
      return;
    }
    this.state.set(s => {
      s.x = this.props.x[selId];
      s.currY = this.props.lines[0].y[selId];
      s.prevY = this.props.lines[1].y[selId];
    });
    if (this.props.hover) {
      this.props.hover(selId);
    }
  };

  render() {
    return (
      <div className="growth_line_chart">
        <div className="header">
          <span className="title">{this.props.name}</span>
          <span
            className={`growth${
              ValueAdaptor.toGrowthValue(
                this.state.get().currY,
                this.state.get().prevY
              ) >= 0
                ? " positive"
                : " negative"
              }`}
          >
            {ValueAdaptor.toGrowth(
              this.state.get().currY,
              this.state.get().prevY
            )}
          </span>
        </div>
        <div className="info">
          <div className="y">
            <span className="current">
              {this.state.get().currY
                ? this.yToStr(this.state.get().currY)
                : "-"}
            </span>
            <span className="previous">
              {this.state.get().prevY
                ? this.yToStr(this.state.get().prevY)
                : "-"}
            </span>
          </div>
        </div>
        <LineChart
          hasAxis={false}
          x={this.props.x}
          hover={this.hover}
          lines={this.props.lines}
          interestFromX={this.props.interestFromX}
          interestUntilX={this.props.interestUntilX}
        />
        <div className="footer">
          <span className="min_x">{this.xToStr(this.minX)}</span>
          <span className="sel_x">
            {this.state.get().x ? this.xToStr(this.state.get().x) : ""}
          </span>
          <span className="max_x">{this.xToStr(this.maxX)}</span>
        </div>
      </div>
    );
  }
}

GrowthLineChart.propTypes = {
  name: PropTypes.string.isRequired,
  hover: PropTypes.func,
  x: PropTypes.array.isRequired,
  xToStr: PropTypes.func,
  yToStr: PropTypes.func,
  interestFromX: PropTypes.any,
  interestUntilX: PropTypes.any,
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      y: PropTypes.array.isRequired,
      yTotal: PropTypes.any.isRequired
    })
  )
};

export default GrowthLineChart;
