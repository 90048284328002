import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
      <div className={`loader_wrapper ${this.props.className || ''}`}>
        <div className="loader"></div>
      </div>
    );
  }
}

export default Loader;
