import React, { Component } from "react";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import State from "../../../utils/State.js";
import Loader from "../../elements/utils/Loader.js";

class LatestDesigns extends Component {
  
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "themes",
      path: "api/brandcontent/latest_designs",
      sortBy: a => -new Date(a.theme_created_at).valueOf()
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  displayEntries = entries => entries.map(e=>{
    return <img key={`${e.theme_id}`} className={`theme ${e.format}`} src={`${e.file_path}`} />
  });

  displayDesigns = data => data.map(x=>{
    return <div className="designs_wrapper" key={`${x.date}`}>
        <div className="date">{ValueAdaptor.toLiteralDate(x.date)}</div>
        {this.displayEntries(x.entries)}
    </div>
  });

  render() {
    if (!this.state.get().ressources.themes.isLoaded) {
      return (
        <div className="page brandcontent latest_designs">
          <Loader />
        </div>
      );
    }
    return (
      <div className="page brandcontent latest_designs">
        {this.displayDesigns(this.state.get().ressources.themes.data)}
      </div>
    );
  }
}

export default LatestDesigns;
