import React, { Component } from "react";
import Loader from "../../elements/utils/Loader.js";
import State from "../../../utils/State.js";
import MultiLinesChart from "../../elements/data/MultiLinesChart.js";
import Design from "../../../utils/design.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import HorizontalBarChart from "../../elements/data/HorizontalBarChart.js";

class Activations extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this)
      .addRessource({
        name: "activationsPerOs",
        path: "api/acquisition/activations_per_os"
      })
      .addRessource({
        name: "activationsPerCountry",
        path: "api/acquisition/activations_per_country"
      })
      .addRessource({
        name: "activationsPerMedia",
        path: "api/acquisition/activations_per_media"
      })
      .add("selId", undefined);
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  //!\ selId used to select a row : need same date order from the 3 given sources (TODO)
  hover = selId => {
    this.state.set(s => {
      s.selId = selId;
    });
  };

  getMultiLineChart(
    title,
    ressource,
    valueMapper,
    yToStr = ValueAdaptor.toAutoUnit
  ) {
    let totalColumns = Math.min(ressource.data.columns.length, 3);
    let lines = [];
    for (let i = 0; i < totalColumns; i++) {
      lines.push({
        name: ressource.data.columns[i],
        color: Design.getColor(i, totalColumns),
        y: ressource.data.rows.map(x => valueMapper(x, i))
      });
    }
    let x = ressource.data.rows.map(x =>
      ValueAdaptor.parseUTCDate(x["registration_date"])
    );
    return (
      <div className="activation_chart_wrapper">
        <div className="chart_title">
          <span>{title}</span>
        </div>
        <MultiLinesChart
          key={`${title}_${this.state.get().selId}`}
          x={x}
          xToStr={ValueAdaptor.toDDMMYYYY}
          yToStr={yToStr}
          hover={this.hover}
          lines={lines}
          hasAxis={true}
          is_log_scale={false}
          selectedId={this.state.get().selId}
        />
      </div>
    );
  }

  getBarChart(ressource, mapper, xToStr = x => x) {
    let maxBins = 12;
    let selRow =
      this.state.get().selId != null
        ? ressource.data.rows[this.state.get().selId]
        : ressource.data.rows.slice(-1)[0];
    let values = [];
    for (let i = 0; i < Math.min(ressource.data.columns.length, maxBins); i++) {
      values.push(mapper(selRow, i));
    }
    return (
      <HorizontalBarChart
        labels={ressource.data.columns.slice(0, maxBins)}
        values={values}
        xToStr={xToStr}
      />
    );
  }

  getDataPannel() {
    return (
      <>
        <div className="row activation_per_os">
          <div className="row_item">
            {this.getMultiLineChart(
              "Registrations",
              this.state.get().ressources.activationsPerOs,
              (x, i) => x["registrations"][i]
            )}
          </div>
          <div className="row_item">
            {this.getMultiLineChart(
              "Activation %",
              this.state.get().ressources.activationsPerOs,
              (x, i) =>
                x["registrations"][i] <= 100
                  ? 0.0
                  : x["activations"][i] / x["registrations"][i],
              ValueAdaptor.toPercent
            )}
          </div>
        </div>
        <div className="row">
          <div className="row_item">
            <h2>Registrations Per Country</h2>
            {this.getBarChart(
              this.state.get().ressources.activationsPerCountry,
              (x, i) => x["registrations"][i],
              ValueAdaptor.toAutoUnit
            )}
          </div>
          <div className="row_item">
            <h2>Activation Rate Per Country</h2>
            {this.getBarChart(
              this.state.get().ressources.activationsPerCountry,
              (x, i) =>
                x["registrations"][i] < 100
                  ? 0
                  : x["activations"][i] / x["registrations"][i],
              ValueAdaptor.toPercent
            )}
          </div>
          <div className="row_item">
            <h2>Registrations Per Media</h2>
            {this.getBarChart(
              this.state.get().ressources.activationsPerMedia,
              (x, i) => x["registrations"][i],
              ValueAdaptor.toAutoUnit
            )}
          </div>
          <div className="row_item">
            <h2>Activation Rate Per Media</h2>
            {this.getBarChart(
              this.state.get().ressources.activationsPerMedia,
              (x, i) =>
                x["registrations"][i] < 100
                  ? 0
                  : x["activations"][i] / x["registrations"][i],
              ValueAdaptor.toPercent
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    let isLoaded = this.state.get().ressources.activationsPerOs.isLoaded;
    isLoaded &= this.state.get().ressources.activationsPerMedia.isLoaded;
    isLoaded &= this.state.get().ressources.activationsPerCountry.isLoaded;
    let content = isLoaded ? this.getDataPannel() : <Loader />;
    return (
      <div className="page acquisition activations">
        <h1>Activations</h1>
        {content}
      </div>
    );
  }
}

export default Activations;
