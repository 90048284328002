import PropTypes from "prop-types";
import React, { Component } from "react";

class Table extends Component {
  rowWrapper(row, rowId) {
    return (
      <tr key={rowId}>
        {row.map((col, colId) =>
          col ? (
            <td
              key={colId}
              rowSpan={col.rowspan}
              className={col.isHeader ? "row_header" : "row_value"}
            >
              {col.value}
            </td>
          ) : null
        )}
      </tr>
    );
  }

  tbodyRows(cols, data) {
    const values = data.map(() => cols.map(() => null));
    let newRowAt = new Set();

    for (let colId = 0; colId < cols.length; colId++) {
      let previous = { value: null, rowspan: 1, isHeader: false };
      for (let rowId = 0; rowId < data.length; rowId++) {
        const currValue = cols[colId].mapper(data[rowId], data, rowId);
        let isSameAsPrev = currValue === previous.value;
        isSameAsPrev |=
          currValue !== null &&
          previous.value !== null &&
          currValue.key !== undefined &&
          currValue.key === previous.value.key;
        if (isSameAsPrev && !newRowAt.has(rowId) && cols[colId].isHeader) {
          previous.rowspan += 1;
        } else {
          newRowAt.add(rowId);
          values[rowId][colId] = {
            value: currValue,
            rowspan: 1,
            isHeader: cols[colId].isHeader
          };
          previous = values[rowId][colId];
        }
      }
    }

    return values.map((row, rowId) => this.rowWrapper(row, rowId));
  }

  theadRows(cols) {
    let results = [];
    let totalColsHeader = this.props.cols.filter(x => x.isHeader).length;

    if (this.props.headingRow) {
      results.push(
        <tr className="heading_row" key="1">
          <td colSpan={totalColsHeader} className="blank"></td>
          {this.props.headingRow.map(({ name, colSpan }, colId) => (
            <td key={colId} colSpan={colSpan}>
              {name}
            </td>
          ))}
        </tr>
      );
    }

    results.push(
      <tr key="2">
        {cols.map(({ name }, colId) => (
          <td key={colId}>{name}</td>
        ))}
      </tr>
    );

    return results;
  }

  render() {
    return (
      <div className={`data_table ${this.props.className || ""}`}>
        <table>
          <thead>{this.theadRows(this.props.cols)}</thead>
          <tbody>{this.tbodyRows(this.props.cols, this.props.data)}</tbody>
        </table>
      </div>
    );
  }
}

Table.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      mapper: PropTypes.func.isRequired,
      isHeader: PropTypes.bool
    })
  ).isRequired,

  data: PropTypes.arrayOf(PropTypes.PropTypes.object.isRequired).isRequired,

  headingRow: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      colSpan: PropTypes.number.isRequired
    })
  ),

  className: PropTypes.string
};

export default Table;
