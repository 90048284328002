import React, { Component } from "react";
import PropTypes from "prop-types";

class TableauReport extends Component {

  reportView() {
    if (
      ["jpg","png"].includes(this.props.report.extension)
    ) {
      return (
        <img
          alt="thumbnail"
          src={`/api/tableau_reports/download/${this.props.report.project_id}`}
        />
      );
    }
    if (this.props.report.extension == "pdf") {
      return (
        <embed
          src={`/api/tableau_reports/download/${this.props.report.project_id}#view=FitH`}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      );
    }
    return <p>Not Found</p>;
  }

  render = () => <div className="report">{this.reportView()}</div>;
}

TableauReport.propTypes = {
  report: PropTypes.object.isRequired
};

export default TableauReport;
