import React, { Component } from "react";
import PropTypes from "prop-types";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";

class GrowthResume extends Component {
  constructor(props) {
    super(props);
    this.onClick = props.onClick || (name => { });
  }

  cellKpi(label, name, value, growth, colorIndice = null) {
    let cellStyle = {};
    let totalStyle = {};

    let valueClassType = "positive";
    if (typeof colorIndice === "number" && colorIndice < 0) {
      valueClassType = "negative";
    }
    if (growth == "∞" || growth == "-") {
      valueClassType = "null";
    }

    return (
      <div
        onClick={() => this.onClick(label)}
        className={`cell ${valueClassType}`}
        style={cellStyle}
      >
        <div className="cell_title">{name}</div>
        <div className="growth">{growth}</div>
        <div className="total" style={totalStyle}>
          {value}
        </div>
      </div>
    );
  }

  rowKpi() {
    return (
      <>
        <div className="growth_resume">
          <div className="row">
            {this.cellKpi(
              "hour",
              "Yesterday",
              ValueAdaptor.toAutoUnit(
                this.props.data["yesterday_value"],
                this.props.unit
              ),
              ValueAdaptor.toGrowth(
                this.props.data["yesterday_value"],
                this.props.data["yesterday_baseline"]
              ),
              ValueAdaptor.toGrowthValue(
                this.props.data["yesterday_value"],
                this.props.data["yesterday_baseline"]
              )
            )}

            {this.cellKpi(
              "day",
              "7 days",
              ValueAdaptor.toAutoUnit(
                this.props.data["last_7days_value"],
                this.props.unit
              ),
              ValueAdaptor.toGrowth(
                this.props.data["last_7days_value"],
                this.props.data["last_7days_baseline"]
              ),
              ValueAdaptor.toGrowthValue(
                this.props.data["last_7days_value"],
                this.props.data["last_7days_baseline"]
              )
            )}
          </div>
          <div className="row">
            {this.cellKpi(
              "week",
              `${ValueAdaptor.toMonthName(new Date(Date.now()))}`,
              ValueAdaptor.toAutoUnit(
                this.props.data["month_value"],
                this.props.unit
              ),
              ValueAdaptor.toGrowth(
                this.props.data["month_value"],
                this.props.data["month_baseline"]
              ),
              ValueAdaptor.toGrowthValue(
                this.props.data["month_value"],
                this.props.data["month_baseline"]
              )
            )}
            {this.cellKpi(
              "month",
              `${ValueAdaptor.toYYYY(new Date(Date.now()))}`,
              ValueAdaptor.toAutoUnit(
                this.props.data["year_value"],
                this.props.unit
              ),
              ValueAdaptor.toGrowth(
                this.props.data["year_value"],
                this.props.data["year_baseline"]
              ),
              ValueAdaptor.toGrowthValue(
                this.props.data["year_value"],
                this.props.data["year_baseline"]
              )
            )}
          </div>
        </div>
      </>
    );
  }

  render = () => this.rowKpi();
}

GrowthResume.propTypes = {
  data: PropTypes.shape({
    yesterday_value: PropTypes.any.isRequired,
    yesterday_baseline: PropTypes.any.isRequired,
    last_7days_value: PropTypes.any.isRequired,
    last_7days_baseline: PropTypes.any.isRequired,
    month_value: PropTypes.any.isRequired,
    month_baseline: PropTypes.any.isRequired,
    year_value: PropTypes.any.isRequired,
    year_baseline: PropTypes.any.isRequired
  }).isRequired,
  unit: PropTypes.string,
  onClick: PropTypes.func
};

export default GrowthResume;
