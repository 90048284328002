import React, { Component } from "react";
import Table from "../elements/data/Table.js";
import ValueAdaptor from "../../utils/ValueAdaptor.js";
import State from "../../utils/State.js";
import Loader from "../elements/utils/Loader.js";
import SimpleLineChart from "../elements/data/SimpleLineChart.js";

class DatabaseHealth extends Component {
  constructor(params) {
    super(params);
    this.state = new State(this).addRessource({
      name: "creditTracking",
      path: "api/finance/credit_tracking_accuracy"
    }).addRessource({
      name: "columnNullRates",
      path: "api/db_health/production_column_null_rates"
    });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getNullRates() {

    let ressource = this.state.get().ressources.columnNullRates;

    if (!ressource.isLoaded) {
      return <Loader/>
    }

    let columns = [
      {
        name: "Table",
        mapper: x => x['table_name'],
        isHeader: true
      },
      {
        name: "Column",
        mapper: x => x['column_name'],
        isHeader: true
      },
      {
        name: "Null % [Current]",
        mapper: x => ValueAdaptor.toPercent(x['current_null_rate'])
      },
      {
        name: "Null % [30 Days Ago]",
        mapper: x => ValueAdaptor.toPercent(x['last_month_null_rate'])
      },
      {
        name: "Null % [1 Year Ago]",
        mapper: x => ValueAdaptor.toPercent(x['last_year_null_rate'])
      }
    ];

    return <Table
    data={ressource.data}
    cols={columns}
    />

  }


  getCreditTrackingChart() {
    let ressource = this.state.get().ressources.creditTracking;

    if (!ressource.isLoaded) {
      return <Loader className="simple_line_chart" />
    }

    let x = ressource.data.map(e => ValueAdaptor.parseUTCDate(e.date));
    let y = ressource.data.map(e => e.accuracy_rate);
    return (
      <SimpleLineChart
        hasAxis={true}
        xName="Date"
        x={x}
        yName="Credit Tracking Accuracy Rate"
        y={y}
        is_log_scale={false}
        yToStr={x => ValueAdaptor.toAutoUnit(x)}
      />
    );
  }

  render() {

    return (
      <div className="page database_health">
        <h1>Database Health</h1>
        <div className="row">
          <div className="row_item">
            {this.getCreditTrackingChart()}
          </div>
          <div className="row_item">
            <h2>Credits Tracking Accuracy</h2>
            <p>
              Ratio of credits we can reconstruct from history indexed at the user creation date 
              (account balance vs expected balance).
            </p>
          </div>
        </div>
        <h2>Column Null Rates</h2>
        <p>
          Null rate evolution per column of the production database.
        </p>
        {this.getNullRates()}
      </div>
    );
  }
}

export default DatabaseHealth;
