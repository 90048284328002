import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div className="page not_found">
        <h1>404 - Page Not found</h1>
      </div>
    );
  }
}

export default NotFound;
