import React, { Component } from "react";
import Loader from "../../elements/utils/Loader.js";
import ValueAdaptor from "../../../utils/ValueAdaptor.js";
import Table from "../../elements/data/Table.js";
import State from "../../../utils/State.js";
import DateSelector from "../../elements/selectors/DateSelector.js";

class BookLayouts extends Component {
  constructor(params) {
    super(params);
    this.fromDate = ValueAdaptor.addDays(ValueAdaptor.utcToday(), -30);
    this.untilDate = ValueAdaptor.utcToday();

    this.state = new State(this)
      .addRessource({
        name: "squarePhotobook",
        path: "api/brandcontent/book_layout_printouts",
        params: {
          format: "square_photobook",
          from_date: this.fromDate,
          until_date: this.untilDate
        }
      })
      .addRessource({
        name: "gazette",
        path: "api/brandcontent/book_layout_printouts",
        params: {
          format: "gazette",
          from_date: this.fromDate,
          until_date: this.untilDate
        }
      });
  }

  componentDidMount() {
    this.state.fetchAndSet();
  }

  getLayoutPopularityTable(ressource) {
    if (!ressource.isLoaded) {
      return <Loader />;
    }

    let columns = [
      { name: "layout_id", mapper: x => x["layout_id"], isHeader: true },
      {
        name: "Preview",
        mapper: x => (
          <>
            <div className="layout_code">
              {(x["code"] || "").split("_").join(" ")}
            </div>
            <img
              src={x["file_path"]}
              className={`layout_thumbnail ${ressource.params.format}`}
            />
          </>
        )
      },
      {
        name: "Books",
        mapper: x => ValueAdaptor.toPercent(x["total_books"])
      },
      {
        name: "Pages/Book",
        mapper: x => ValueAdaptor.toAutoUnit(x["avg_pages"])
      },
      {
        name: "Pages",
        mapper: x => ValueAdaptor.toPercent(x["total_pages"])
      }
    ];

    return <Table data={ressource.data} cols={columns} />;
  }

  controler() {
    return (
      <div className="controler">
        Number of pages printed for a given layout from
        <DateSelector
          defaultDate={this.fromDate}
          callback={x => {
            this.state.set(s => {
              s.ressources.squarePhotobook.params.from_date = x;
              s.ressources.gazette.params.from_date = x;
            });
          }}
        />
        until
        <DateSelector
          defaultDate={this.untilDate}
          callback={x => {
            this.state.set(s => {
              s.ressources.squarePhotobook.params.until_date = x;
              s.ressources.gazette.params.until_date = x;
            });
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="page brandcontent book_layouts">
        <h1>Book Layouts</h1>
        {this.controler()}
        <div className="row">
          <div className="row_item">
            <h2>Square Photobook</h2>
            {this.getLayoutPopularityTable(
              this.state.get().ressources.squarePhotobook
            )}
          </div>
          <div className="row_item">
            <h2>Gazette</h2>
            {this.getLayoutPopularityTable(this.state.get().ressources.gazette)}
          </div>
        </div>
      </div>
    );
  }
}

export default BookLayouts;
